import React from 'react';
import SuccessComponent from '../components/SuccessComponent';

function SuccessPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200">
      <SuccessComponent />
    </div>
  );
}

export default SuccessPage;