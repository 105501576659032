import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Leaderboard from "./pages/Leaderboard";
import Sitemap from "./pages/sitemap";
import Landing from "./pages/landing";
import SignIn from "./pages/signin";
import SignUp from "./pages/signup";
import SuccessPage from "./pages/successlms";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./pages/forgotpassword";
import ResetPassword from "./pages/resetpassword";
import Home from "./pages/home";
import Create from "./pages/create";
import Topics from "./pages/topics";
import Course from "./pages/course";
import PricingPage from "./pages/trialpayments";
import SubscriptionButton from "./pages/subscriptions";
import Certificate from "./pages/certificate";
import Profile from "./pages/profile";
import Pricing from "./pages/pricing";
import Features from "./pages/features";
import About from "./pages/about";
import Payment from "./pages/payment";
import Success from "./pages/success";
import Manage from "./pages/manage";
import Failed from "./pages/failed";
import Contact from "./pages/contact";
import DashBoard from "./admin/dashboard";
import Users from "./admin/users";
import Courses from "./admin/courses";
import PaidUsers from "./admin/paidusers";
import Admins from "./admin/admins";
import Contacts from "./admin/contacts";
import Terms from "./admin/terms";
import Privacy from "./admin/privacy";
import Cancellation from "./admin/cancellation";
import Refund from "./admin/refund";
import Billing from "./admin/billing";
import TermsPolicy from "./pages/termspolicy";
import PrivacyPolicy from "./pages/privacypolicy";
import CancelPolicy from "./pages/cancelpolicy";
import RefundPolicy from "./pages/refundpolicy";
import BillingPolicy from "./pages/billingpolicy";
import Error from "./pages/error";
import VerifyEmail from "./pages/verify-email";
import Pending from "./pages/pending";
import Successflw from "./pages/successflw";
import UpdatePayment from "./pages/updatePayment";
import ReactGA from "react-ga";
const TRACKING_ID_1 = "G-9X7C374D7Y";
const TRACKING_ID_2 = "G-9YT0CNK5CR";

ReactGA.initialize([
  { trackingId: TRACKING_ID_1 },
  { trackingId: TRACKING_ID_2 },
]);
function App() {
  return (
    <Router>
      <div>
        <ToastContainer
          limit={3}
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          {/* Main App */}
          <Route path="/successlms" element={<SuccessPage />} />
          <Route path="/subscriptions" element={<SubscriptionButton />} />
          <Route path="/" exact element={<Landing />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/updatePayment" element={<UpdatePayment />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/successflw" element={<Successflw />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/home" element={<Home />} />
          <Route path="/create" element={<Create />} />
          <Route path="/topics" element={<Topics />} />
          <Route path="/course" element={<Course />} />
          <Route path="/certificate" element={<Certificate />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/features" element={<Features />} />
          <Route path="/about" element={<About />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/trialpayments" element={< PricingPage />} />
          <Route path="/success" element={<Success />} />
          <Route path="/subscription" element={<Manage />} />
          <Route path="/failed" element={<Failed />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<TermsPolicy />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/cancellation" element={<CancelPolicy />} />
          <Route path="/refund" element={<RefundPolicy />} />
          <Route path="/billing" element={<BillingPolicy />} />
          <Route path="/pending" element={<Pending />} />
          {/* Admin Panel */}
          <Route path="/dashBoard" element={<DashBoard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/paid" element={<PaidUsers />} />
          <Route path="/admins" element={<Admins />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cancellation" element={<Cancellation />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/leaderboard" element={<Leaderboard />} />

          {/* Error Page */}
          <Route path="/error" element={<Error />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
