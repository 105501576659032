import React from 'react';
import PricingComponent from '../components/PricingComponent';

function PricingPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200">
      <PricingComponent />
    </div>
  );
}

export default PricingPage;