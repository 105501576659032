import axios from "axios";
import React, { useState, useEffect } from "react";
import { serverURL } from "src/constants";
import { toast } from "react-toastify";
import streak from "../res/img/streak.png"

export const StreakPopup = ({ title, score, message, position }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [streakData, setStreakData] = useState({
    courseNotifyStatus: false,
    quizNotifyStatus: false,
    signQuizNotifyStatus: false,
    signTeacherNotifyStatus: false,
    quizStreakCount: 0,
    teacherStreakCount: 0,
  });
  const email = sessionStorage.getItem("email") || "";
  const closePopup = () => setShowPopup(false);

  const showToast = async (msg) => {
    toast(msg, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleCopyText = () => {
    navigator.clipboard
      .writeText("https://aiteacher.learnrithm.com/signup")
      .then(() => {
        showToast("Link Copied Share it on your platforms #learnrithm");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  useEffect(() => {
    const getStreakType = () => {
      switch (position) {
        case "sign-in-teacher":
          return "registerSignTeacher";
        case "create-course":
          return "registerCreateCourse";
        default:
          return null;
      }
    };
    async function registerStreak(streakType) {
      try {
        const { data } = await axios.post(
          `${serverURL}/api/NotificationStreakCheck`,
          { email, type: streakType }
        );

        if (data) {
          setStreakData((prevData) => ({
            ...prevData,
            quizStreakCount: data.quizStreakCount || prevData.quizStreakCount,
            teacherStreakCount:
              data.teacherStreakCount || prevData.teacherStreakCount,
          }));
        }
      } catch (error) {
        console.error("Error registering streak:", error);
      }
    }
    const checkShouldShowPopup = (data) => {
      return (
        (position === "sign-in-teacher" && !data.signTeacherNotifyStatus) ||
        (position === "create-course" && !data.courseNotifyStatus)
      );
    };
    async function fetchNotificationData() {
      try {
        const { data } = await axios.post(
          `${serverURL}/api/NotificationStreakCheck`,
          { email, type: "check" }
        );

        if (data) {
          setStreakData({
            courseNotifyStatus: data.courseNotifyStatus,
            quizNotifyStatus: data.quizNotifyStatus,
            signQuizNotifyStatus: data.signQuizNotifyStatus,
            signTeacherNotifyStatus: data.signTeacherNotifyStatus,
            quizStreakCount: data.quizStreakCount,
            teacherStreakCount: data.teacherStreakCount,
          });

          const shouldShowPopup = checkShouldShowPopup(data);
          setShowPopup(shouldShowPopup);
          if (position !== "header") {
            const streakType = getStreakType(position);
            if (streakType) {
              registerStreak(streakType);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching notification streak data:", error);
      }
    }

    if (email) {
      fetchNotificationData();
    }
  }, [email, position]);

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => setShowPopup(false), 60000);
      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  if (position === "header") {
    return (
      <div className="relative flex items-center justify-center">
        <img src={streak} alt="" className="w-12 h-12" />
        <span className="absolute text-white text-lg font-bold mt-1">
          {streakData.teacherStreakCount}
        </span>
      </div>
    );
  }

  return (
    showPopup && (
      <div className="fixed bottom-5 right-5 bg-black dark:bg-white text-white dark:text-black p-4 rounded-lg shadow-lg z-50 flex flex-col items-center max-w-xs">
        <div className="flex flex-col items-center mb-4">
          <h2 className="text-2xl font-bold text-yellow-500 mb-2">{score}</h2>
          <p className="text-lg font-semibold">{title}</p>
        </div>
        <p className="text-center mb-4">{message}</p>
        <div className="text-center mb-4">
          <p>Quiz Streak: {streakData.quizStreakCount} days</p>
          <p>Teacher Streak: {streakData.teacherStreakCount} days</p>
        </div>
        <div className="flex justify-between w-full">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mr-2"
            onClick={handleCopyText}
          >
            Share
          </button>
          <button
            className="bg-white dark:bg-black dark:text-white hover:bg-white text-black font-bold py-2 px-4 rounded w-full"
            onClick={closePopup}
          >
            Continue
          </button>
        </div>
      </div>
    )
  );
};
