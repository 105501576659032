import React, { useState, useEffect } from "react";
import axios from "axios";
import { serverURL } from "../constants";
import { Card, Spinner } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import found from "../res/img/found.svg";
import { toast } from "react-toastify";

const UserCourses = () => {
  const [courses, setCourses] = useState([]);
  const [mName, setMName] = useState("");
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    const fetchUserCourses = async () => {
      const user = sessionStorage.getItem("uid");
      const postURL = `${serverURL}/api/courses`;
      try {
        const response = await axios.post(postURL, { user });
        setCourses(response.data.courses);
        setMName(sessionStorage.getItem("mName"));
        setProcessing(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserCourses();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const checkEmailStatus = async () => {
      const email = sessionStorage.getItem("email");
      if (email) {
        try {
          const response = await axios.get(
            `${serverURL}/api/checkVerificationStatus`,
            {
              params: { email },
            }
          );

          if (!response.data.isVerified) {
            toast.warn(
              "Your email is not verified. Please verify it to proceed."
            );
          } else {
            toast.success("Your email is verified! You can now proceed.");
          }
        } catch (error) {
          console.error("Error checking email status:", error);
          toast.error("Failed to check email verification status.");
        }
      }
    };

    checkEmailStatus();
  }, []);

  const handleCourse = (content, mainTopic, type, courseId, completed, end) => {
    const jsonData = JSON.parse(content);
    sessionStorage.setItem("courseId", courseId);
    sessionStorage.setItem("first", completed);
    sessionStorage.setItem("jsonData", JSON.stringify(jsonData));
    let ending = "";
    if (completed) {
      ending = end;
    }
    navigate("/course", {
      state: {
        jsonData: jsonData,
        mainTopic: mainTopic.toUpperCase(),
        type: type.toLowerCase(),
        courseId: courseId,
        end: ending,
      },
    });
  };

  const handleCertificate = (mainTopic, end) => {
    const ending = new Date(end).toLocaleDateString();
    navigate("/certificate", {
      state: { courseTitle: mainTopic, end: ending },
    });
  };

  const style = {
    root: {
      base: "max-w-sm flex rounded-none border border-black bg-white shadow-none dark:border-white dark:bg-black m-4",
      children: "flex h-full flex-col justify-center gap-3 p-5",
      horizontal: {
        off: "flex-col",
        on: "flex-col md:max-w-xl md:flex-row",
      },
      href: "hover:bg-white dark:hover:bg-black",
    },
    img: {
      base: "",
      horizontal: {
        off: "rounded-none",
        on: "h-96 w-full rounded-none object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg",
      },
    },
  };

  return (
    <div className="my-4">
      {processing ? (
        <div className="text-center h-screen w-screen flex items-center justify-center">
          <Spinner size="xl" className="fill-black dark:fill-white" />
        </div>
      ) : (
        <>
          {mName && (
            <div className="text-center my-4">
              <h2 className="text-xl font-black text-black dark:text-white">
                Welcome, {mName}
              </h2>
            </div>
          )}

          {!courses || courses.length === 0 ? (
            <div className="text-center h-center flex flex-col items-center justify-center">
              <img alt="img" src={found} className="max-w-sm h-3/6" />
              <p className="text-black font-black dark:text-white text-xl">
                No courses found.
              </p>
            </div>
          ) : (
            <div className="my-4 flex flex-wrap">
              {courses.map((course) => (
                <Card key={course._id} imgSrc={course.photo} theme={style}>
                  <h5 className="text-xl font-black tracking-tight text-black dark:text-white">
                    {course.mainTopic.toUpperCase()}
                  </h5>
                  <p className="font-normal text-sm capitalize text-black dark:text-white">
                    {course.type}
                  </p>
                  <p className="font-normal text-sm text-black dark:text-white">
                    {new Date(course.date).toLocaleDateString()}
                  </p>
                  <div className="flex-row flex space-x-4">
                    <button
                      onClick={() =>
                        handleCourse(
                          course.content,
                          course.mainTopic,
                          course.type,
                          course._id,
                          course.completed,
                          course.end
                        )
                      }
                      className="bg-black text-white px-5 py-2 font-medium dark:bg-white dark:text-black"
                    >
                      Continue
                    </button>
                    {course.completed ? (
                      <button
                        onClick={() =>
                          handleCertificate(course.mainTopic, course.end)
                        }
                        className="border-black text-black border px-5 py-2 font-medium dark:border-white dark:text-white"
                      >
                        Certificate
                      </button>
                    ) : null}
                  </div>
                </Card>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserCourses;
