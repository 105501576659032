import React from 'react';
import { Button } from 'flowbite-react';
import { FiMoon, FiSun } from 'react-icons/fi';

const DarkModeToggle = () => {
  const [isDark, setIsDark] = React.useState(() => 
    sessionStorage.getItem('darkMode') === 'true'
  );

  const toggleDarkMode = () => {
    const newMode = !isDark;
    setIsDark(newMode);
    sessionStorage.setItem('darkMode', newMode);
    document.documentElement.classList.toggle('dark');
  };

  return (
    <Button
      color="gray"
      onClick={toggleDarkMode}
      className="p-2"
    >
      {isDark ? <FiSun className="h-5 w-5" /> : <FiMoon className="h-5 w-5" />}
    </Button>
  );
};

export default DarkModeToggle;