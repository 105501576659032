import React, { useState, useEffect } from 'react';
import { HiOutlineMailOpen, HiX } from 'react-icons/hi';
import axios from 'axios';
import { toast } from "react-toastify";
import { serverURL } from "../constants";

const EmailVerificationBar = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  const [message, setMessage] = useState("Verify now");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const storedEmail = sessionStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  useEffect(() => {
    let countdownInterval;
    if (timeLeft > 0) {
      countdownInterval = setInterval(() => {
        setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    } else {
      setMessage("Verify now");
    }

    return () => clearInterval(countdownInterval);
  }, [timeLeft]);

  const showToast = (msg) => {
    toast(msg, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleClick = async () => {
    if (timeLeft > 0) return;

    setTimeLeft(60);
    const countdownInterval = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    try {
      const response = await axios.post(
        `${serverURL}/api/resendVerificationEmail`,
        { email }
      );

      if (response.data.success) {
        showToast(response.data.message);
      } else {
        showToast(response.data.message);
      }
    } catch (error) {
      showToast("Internal Server Error");
    } finally {
      clearInterval(countdownInterval);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-3 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto flex items-center justify-between flex-wrap">
        <div className="flex items-center flex-1 min-w-0">
          <span className="flex p-2 rounded-lg bg-blue-600">
            <HiOutlineMailOpen className="h-6 w-6" aria-hidden="true" />
          </span>
          <p className="ml-3 font-medium truncate">
            <span className="md:hidden">Please verify your email.</span>
            <span className="hidden md:inline">Please verify your email address to access all features.</span>
          </p>
        </div>
        <div className="flex-shrink-0 order-2 sm:order-3 sm:ml-3">
          <button
            type="button"
            className="-mr-1 flex p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2 transition"
            onClick={() => setIsVisible(false)}
          >
            <span className="sr-only">Dismiss</span>
            <HiX className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
          <button
            onClick={handleClick}
            className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50 transition"
            disabled={timeLeft > 0}
          >
            {timeLeft > 0 ? `Retry in ${timeLeft}s` : message}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationBar;