import React from 'react';

const CourseTable = ({ datas }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white dark:bg-black">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-200 dark:border-gray-700">User Email</th>
            <th className="py-2 px-4 border-b border-gray-200 dark:border-gray-700">Main Topic</th>
            <th className="py-2 px-4 border-b border-gray-200 dark:border-gray-700">Type</th>
            <th className="py-2 px-4 border-b border-gray-200 dark:border-gray-700">Completed</th>
            <th className="py-2 px-4 border-b border-gray-200 dark:border-gray-700">Date</th>
          </tr>
        </thead>
        <tbody>
          {datas.map((course) => (
            <tr key={course._id}>
              <td className="py-2 px-4 border-b border-gray-200 dark:border-gray-700">{course.userEmail}</td>
              <td className="py-2 px-4 border-b border-gray-200 dark:border-gray-700">{course.mainTopic}</td>
              <td className="py-2 px-4 border-b border-gray-200 dark:border-gray-700">{course.type}</td>
              <td className="py-2 px-4 border-b border-gray-200 dark:border-gray-700">{course.completed ? 'Yes' : 'No'}</td>
              <td className="py-2 px-4 border-b border-gray-200 dark:border-gray-700">{new Date(course.date).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CourseTable;