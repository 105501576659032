import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Check } from "lucide-react";
import axios from 'axios';

const starterPlan = {
  name: 'Starter',
  description: 'Perfect for individuals and small teams',
  features: [
    'Unlimited Access to Learn, all day, every day for a week! No ads, just pure learning vibes all week',
    'Access to Learn with Videos and images',
    'Access to learn local in your local language',
    'Ability to learn based on your university curriculum',
    'Ability to learn based on your Country\'s Curriculum',
    'Ability to Upload Your Study Material'
  ],
  price: { weekly: 2, monthly: 10, yearly: 50 }
};

const checkoutUrls = {
  weekly: 'https://learnrithm.lemonsqueezy.com/buy/209b7e92-4ce1-492a-a838-1d8e9d83e346',
  monthly: 'https://learnrithm.lemonsqueezy.com/buy/59b3e63c-2328-4770-98b5-a630a90f7a84',
  yearly: 'https://learnrithm.lemonsqueezy.com/buy/1aa5a3b3-eecd-43c3-ba57-af7761a40efe'
};

function generateOrderId() {
  return 'ORD-' + Date.now() + '-' + Math.random().toString(36).substr(2, 9);
}

function PricingComponent() {
  const [duration, setDuration] = useState('monthly');
  const [isLemonReady, setLemonReady] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  const navigate = useNavigate();

  const saveTransaction = useCallback(async (orderId) => {
    const subscriptionStart = new Date();
    let subscriptionEnd;

    if (duration === 'weekly') {
      subscriptionEnd = new Date(subscriptionStart);
      subscriptionEnd.setDate(subscriptionEnd.getDate() + 7);
    } else if (duration === 'monthly') {
      subscriptionEnd = new Date(subscriptionStart);
      subscriptionEnd.setMonth(subscriptionEnd.getMonth() + 1);
    } else if (duration === 'yearly') {
      subscriptionEnd = new Date(subscriptionStart);
      subscriptionEnd.setFullYear(subscriptionEnd.getFullYear() + 1);
    }

    try {
      await axios.post(`https://aiteacher.learnrithm.com/api/transactions`, {
        email,
        duration,
        subscriptionStart: subscriptionStart.toISOString(),
        subscriptionEnd: subscriptionEnd.toISOString(),
        orderId,
        createdAt: subscriptionStart.toISOString(),
      });

      await axios.post(`https://aiteacher.learnrithm.com/api/updateUserPlan`, {
        email,
        plan: duration
      });

      navigate('/successlms');
    } catch (error) {
      console.error('Error saving transaction or updating user plan:', error.response ? error.response.data : error.message);
    }
  }, [duration, email, navigate]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.lemonsqueezy.com/js/lemon.js';
    script.async = true;
    script.defer = true;

    script.onload = () => {
      setTimeout(() => {
        if (window.LemonSqueezy) {
          setLemonReady(true);

          window.LemonSqueezy.Setup({
            eventHandler: (data) => {
              if (data.event === "Checkout.Success") {
                const orderId = localStorage.getItem('currentOrderId');
                saveTransaction(orderId);
              }
            }
          });
        } else {
          console.error("Lemon Squeezy script loaded but not initialized.");
        }
      }, 1000);
    };

    script.onerror = () => {
      console.error("Error loading the Lemon Squeezy script.");
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [navigate, saveTransaction]);

  const openCheckout = () => {
    if (!isLemonReady) {
      console.error("Lemon Squeezy script not loaded");
      return;
    }

    const orderId = generateOrderId();
    localStorage.setItem('currentOrderId', orderId);

    const checkoutUrl = checkoutUrls[duration];
    window.LemonSqueezy.Url.Open(checkoutUrl);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-100 to-indigo-200 p-4 md:p-8 flex flex-col items-center justify-center">
      <motion.div
        className="w-full max-w-4xl space-y-8"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="text-center space-y-4" variants={itemVariants}>
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900">Start Your Learning Journey</h1>
          <p className="text-xl text-gray-600">Try our platform for free and unlock your potential</p>
        </motion.div>

        <motion.div className="flex justify-center items-center space-x-4 py-4" variants={itemVariants}>
          {['weekly', 'monthly', 'yearly'].map((period) => (
            <button
              key={period}
              className={`px-6 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                duration === period ? 'bg-indigo-600 text-white shadow-lg' : 'bg-white text-gray-600 hover:bg-gray-100'
              }`}
              onClick={() => setDuration(period)}
            >
              {period.charAt(0).toUpperCase() + period.slice(1)}
            </button>
          ))}
        </motion.div>

        <motion.div
          className="bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-300 hover:shadow-2xl transform hover:-translate-y-1"
          variants={itemVariants}
        >
          <div className="p-8">
            <h2 className="text-2xl font-bold text-gray-900">{starterPlan.name}</h2>
            <p className="text-gray-600 mt-2">{starterPlan.description}</p>
            <div className="mt-4 flex items-end">
              <span className="text-4xl font-bold text-indigo-600">$0</span>
              <span className="text-xl text-gray-500 ml-2 line-through">
                ${starterPlan.price[duration]}
              </span>
              <span className="text-sm text-indigo-600 ml-2">/{duration.slice(0, -2)}</span>
            </div>
            <div className="mt-2">
              <span className="bg-indigo-100 text-indigo-800 text-sm font-medium px-2.5 py-0.5 rounded-full">
                2-day free trial
              </span>
            </div>
            <ul className="mt-6 space-y-4">
              {starterPlan.features.map((feature, i) => (
                <li key={i} className="flex items-start">
                  <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span className="text-gray-600">{feature}</span>
                </li>
              ))}
            </ul>
            <div className="mt-8">
              <input
                type="email"
                value={email}
                disabled
                placeholder="Enter your email"
                className="w-full p-3 border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed"
              />
            </div>
          </div>
          <div className="p-8 bg-gray-50">
            <motion.button
              onClick={openCheckout}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full py-3 px-4 rounded-lg text-white font-medium transition-colors duration-200 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Start Free Trial
            </motion.button>
          </div>
        </motion.div>

        <motion.div className="text-center text-gray-600 text-sm" variants={itemVariants}>
          <p>No credit card required for trial. Cancel anytime.</p>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default PricingComponent;