import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {  logo, name, serverURL, websiteURL } from "../constants";
import LogoComponent from "../components/LogoComponent";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading } from "react-icons/ai";

const SignIn = () => {
  const storedTheme = sessionStorage.getItem("darkMode");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("auth")) {
      navigate("/home");
    }
  }, [navigate]);

  const showToast = (msg) => {
    setProcessing(false);
    toast(msg, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleSignin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      showToast("Please fill in all required fields");
      return;
    }
  
    const postURL = `${serverURL}/api/signin`;
  
    try {
      setProcessing(true);
      const response = await axios.post(postURL, {
        email,
        password,
      });
      if (response.data.success) {
        showToast(response.data.message);
        sessionStorage.setItem('email', response.data.userData.email);
        sessionStorage.setItem('mName', response.data.userData.mName);
        sessionStorage.setItem('auth', true);
        sessionStorage.setItem('uid', response.data.userData._id);
        sessionStorage.setItem('type', response.data.userData.type);
        sessionStorage.setItem('isVerified', `${response.data.userData.isVerified}`);
  
        const userType = response.data.userData.type;
        if (userType === 'weekly' || userType === 'monthly' || userType === 'yearly') {
          navigate("/home");
        } else if (userType === 'free') {
          navigate("/trialpayments");
        }
      } else {
        showToast(response.data.message);
      }
    } catch (error) {
      showToast("Internal Server Error");
    }
  };
  return (
    <>
      <Helmet>
        <title>Sign In - {name}</title>
        <meta name="description" content={`Sign in to your ${name} account.`} />
        <meta name="keywords" content={`${name}, sign in, login, account`} />
        <meta property="og:title" content={`Sign In - ${name}`} />
        <meta property="og:description" content={`Sign in to your ${name} account.`} />
        <meta property="og:url" content={`${websiteURL}/signin`} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Sign In - ${name}`} />
        <meta name="twitter:description" content={`Sign in to your ${name} account.`} />
        <meta name="twitter:image" content={logo} />
      </Helmet>

      <div className="flex min-h-full flex-col justify-center px-6 py-12 bg-white">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSignin} className="space-y-6">
            {/* Logo centered within the form */}
            <div className="flex flex-col items-center">
              <LogoComponent isDarkMode={storedTheme} />
              <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">
                Sign in to your account
              </h2>
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium text-gray-900">
                  Password
                </label>
                <div className="text-sm">
                  <span
                    onClick={() => navigate("/forgot-password")}
                    className="font-semibold text-indigo-600 hover:text-indigo-500 cursor-pointer"
                  >
                    Forgot password?
                  </span>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="current-password"
                  className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={processing}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 disabled:opacity-50"
              >
                {processing ? (
                  <AiOutlineLoading className="h-5 w-5 animate-spin" />
                ) : (
                  "Sign In"
                )}
              </button>
            </div>
          </form>

          <p className="mt-6 text-center text-sm text-gray-500">
            Don't have an account?{" "}
            <span
              onClick={() => navigate("/signup")}
              className="font-semibold text-indigo-600 hover:text-indigo-500 cursor-pointer"
            >
              Sign up
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default SignIn;