import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footers from "../components/footers";
import { Button, Label } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { toast } from "react-toastify";
import { serverURL } from "../constants";
import axios from "axios";
import { BsArrowRightCircle, BsCheckCircle } from "react-icons/bs";

const Profile = () => {
  const [mName, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setName(sessionStorage.getItem("mName") || "");
      setEmail(sessionStorage.getItem("email") || "");
      setIsVerified(sessionStorage.getItem("isVerified") === "true");
    }
  }, []);

  const showToast = (msg) => {
    toast(msg, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setProcessing(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !mName) {
      showToast("Please fill in all required fields");
      return;
    }

    setProcessing(true);
    const uid = sessionStorage.getItem("uid");

    try {
      const response = await axios.post(`${serverURL}/api/profile`, {
        email,
        mName,
        password,
        uid,
      });

      if (response.data.success) {
        showToast(response.data.message);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("mName", mName);
      } else {
        showToast(response.data.message);
      }
    } catch (error) {
      showToast("Internal Server Error");
    } finally {
      setProcessing(false);
    }
  };

  const handleResendVerificationEmail = async () => {
    if (timeLeft > 0) return;

    setTimeLeft(60);
    const countdownInterval = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    try {
      const response = await axios.post(
        `${serverURL}/api/resendVerificationEmail`,
        { email }
      );

      if (response.data.success) {
        showToast(response.data.message);
      } else {
        showToast(response.data.message);
      }
    } catch (error) {
      showToast("Internal Server Error");
    } finally {
      clearInterval(countdownInterval);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-r from-blue-500 to-indigo-600 text-white">
      <Header isHome={true} className="sticky top-0 z-50" />
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-bold text-center text-blue-900 mb-6">Profile</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <Label className="block text-sm font-medium text-gray-700" htmlFor="name1">
                Name
              </Label>
              <input
                value={mName}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-black"
                id="name1"
                type="text"
              />
            </div>

            <div>
              <Label className="block text-sm font-medium text-gray-700" htmlFor="email1">
                Email
              </Label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-l-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-black"
                  id="email1"
                  type="email"
                  disabled
                />
                {isVerified ? (
                  <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-green-50 text-green-700">
                    <BsCheckCircle className="w-5 h-5" />
                    <span className="ml-2">Verified</span>
                  </span>
                ) : (
                  <Button
                    className="bg-blue-600 text-white text-sm px-4 py-2 rounded-r-md"
                    onClick={handleResendVerificationEmail}
                    disabled={timeLeft > 0}
                  >
                    {timeLeft > 0 ? `Retry in ${timeLeft}s` : "Verify Now"}
                    <BsArrowRightCircle className="ml-2 w-5 h-5" />
                  </Button>
                )}
              </div>
            </div>

            <div>
              <Label className="block text-sm font-medium text-gray-700" htmlFor="password1">
                New Password
              </Label>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-black"
                id="password1"
                type="password"
              />
            </div>

            <div>
              <Button
                isProcessing={processing}
                processingSpinner={<AiOutlineLoading className="h-5 w-5 animate-spin" />}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </main>
      <Footers className="mt-auto bg-gradient-to-r from-blue-500 to-indigo-600 text-white" />
    </div>
  );
};

export default Profile;