import { useEffect, useState } from 'react';

const SubscriptionButton = () => {
  const [isLemonReady, setLemonReady] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.lemonsqueezy.com/js/lemon.js';
    script.async = true;
    script.defer = true;

    script.onload = () => {
      if (window.LemonSqueezy) {
        console.log("Lemon Squeezy loaded successfully.");
        setLemonReady(true);

        // Setup event handling for successful checkout
        window.LemonSqueezy.Setup({
          eventHandler: (data) => {
            if (data.event === "Checkout.Success") {
              console.log("Checkout was successful!", data);
              // Redirect to the success page
              window.location.href = 'https://aiteacher.learnrithm.com/success'; // Your success URL
            }
          }
        });
      } else {
        console.error("Lemon Squeezy script loaded but not initialized.");
      }
    };

    script.onerror = () => {
      console.error("Error loading the Lemon Squeezy script.");
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const openCheckout = () => {
    if (!isLemonReady) {
      console.error("Lemon Squeezy script not loaded");
      return;
    }

    const checkoutUrl = 'https://learnrithm.lemonsqueezy.com/buy/7f537662-d662-4bf2-a165-6b83eebcde78'; // Replace with actual URL
    window.LemonSqueezy.Url.Open(checkoutUrl);
  };

  return <button onClick={openCheckout}>Subscribe Now</button>;
};

export default SubscriptionButton;
