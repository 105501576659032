import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { IoIosTimer } from 'react-icons/io';
import { BsSearch } from 'react-icons/bs';
import { PiVideo } from 'react-icons/pi';
import Header from '../components/header';
import { logo, name, websiteURL } from '../constants';

const Features = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0, opacity: 1,
    },
  };

  const cardsFeatures = [
    {
      id: 1,
      title: 'Topic Input',
      description: 'Easily enter topics and subtopics with an intuitive interface',
    },
    {
      id: 2,
      title: 'AI Course Generation',
      description: 'Generate comprehensive courses with AI in seconds',
    },
    {
      id: 3,
      title: 'Customizable Content',
      description: 'Edit and customize the generated content to suit your needs',
    },
    {
      id: 4,
      title: 'Multimedia Integration',
      description: 'Include videos, images, and interactive content seamlessly',
    },
    {
      id: 5,
      title: 'Collaboration Tools',
      description: 'Work with team members in real-time on course creation',
    },
  ];

  const cardBenefit = [
    {
      id: 1,
      title: 'Time Efficiency',
      description: 'Save hours of manual planning with instant course generation',
      icon: <IoIosTimer className="text-5xl text-white mb-4" />,
    },
    {
      id: 2,
      title: 'Improved Search',
      description: 'Quickly find courses and topics with enhanced search capabilities',
      icon: <BsSearch className="text-5xl text-white mb-4" />,
    },
    {
      id: 3,
      title: 'Engaging Content',
      description: 'Enhance learning with rich multimedia content',
      icon: <PiVideo className="text-5xl text-white mb-4" />,
    },
  ];

  const cardsWork = [
    {
      id: 1,
      title: 'Enter Topics',
      description: 'Begin by entering your desired topics and subtopics',
    },
    {
      id: 2,
      title: 'Generate Courses',
      description: 'Let our AI create detailed courses tailored to your input',
    },
    {
      id: 3,
      title: 'Customize Content',
      description: 'Edit and enhance the content to match your style',
    },
    {
      id: 4,
      title: 'Publish & Share',
      description: 'Publish your courses and share them with learners worldwide',
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-500 to-indigo-600">
      <Helmet>
        <title>{name} Features | AI-Powered Learning Platform</title>
        <meta
          name="description"
          content={`Discover the powerful features of ${name}, the AI-powered platform revolutionizing modern education.`}
        />
        <meta
          name="keywords"
          content={`${name} Features, AI Education Platform, Modern Learning, AI Teacher, Personalized Learning`}
        />
        <meta property="og:title" content={`${name} Features | AI-Powered Learning Platform`} />
        <meta
          property="og:description"
          content={`Explore how ${name} enhances learning with AI-driven features.`}
        />
        <meta property="og:url" content={`${websiteURL}/features`} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${name} Features | AI-Powered Learning Platform`} />
        <meta
          name="twitter:description"
          content={`Discover how ${name} is transforming education with advanced features.`}
        />
        <meta name="twitter:image" content={logo} />
      </Helmet>

      <Header />

      {/* Announcement Banner */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex justify-center w-full py-4"
      >
        <motion.div className="inline-flex items-center gap-2 px-4 py-2 bg-white/95 rounded-full text-sm text-gray-900">
          <span>🎉 New Features Added to {name} AI Platform</span>
          <motion.span
            animate={{ x: [0, 3, 0] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
          >
            →
          </motion.span>
        </motion.div>
      </motion.div>

      {/* Main Content */}
      <motion.div
        className="container mx-auto px-4 pt-20 pb-32"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Intro Section */}
        <motion.div className="max-w-4xl mx-auto text-center" variants={itemVariants}>
          <h1 className="text-7xl font-bold tracking-tight text-white max-md:text-4xl">
            Features
          </h1>
          <motion.p
            className="mt-8 text-xl text-gray-200 max-w-2xl mx-auto leading-relaxed max-md:text-lg"
            variants={itemVariants}
          >
            Discover the innovative features that make {name} the leading AI-powered learning platform.
          </motion.p>
        </motion.div>

        {/* Features Cards */}
        <motion.div
          className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8"
          variants={containerVariants}
        >
          {cardsFeatures.map((card) => (
            <motion.div
              key={card.id}
              className="bg-white/10 rounded-xl p-6 text-white"
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300, damping: 20 }}
            >
              <h5 className="text-2xl font-bold mb-2">{card.title}</h5>
              <p className="text-base">{card.description}</p>
            </motion.div>
          ))}
        </motion.div>

        {/* Benefits Section */}
        <motion.div
          className="mt-32 max-w-4xl mx-auto text-center"
          variants={itemVariants}
        >
          <h2 className="text-5xl font-bold text-white">Benefits</h2>
        </motion.div>
        <motion.div
          className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8"
          variants={containerVariants}
        >
          {cardBenefit.map((card) => (
            <motion.div
              key={card.id}
              className="bg-white/10 rounded-xl p-6 text-white flex flex-col items-center"
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300, damping: 20 }}
            >
              <div className="mb-4">{card.icon}</div>
              <h5 className="text-2xl font-bold mb-2">{card.title}</h5>
              <p className="text-base text-center">{card.description}</p>
            </motion.div>
          ))}
        </motion.div>

        {/* How It Works Section */}
        <motion.div
          className="mt-32 max-w-4xl mx-auto text-center" variants={itemVariants}>
          <h2 className="text-5xl font-bold text-white">How It Works</h2>
        </motion.div>
        <motion.div
          className="mt-16 grid grid-cols-1 md:grid-cols-4 gap-8"
          variants={containerVariants}
        >
          {cardsWork.map((card) => (
            <motion.div
              key={card.id}
              className="bg-white/10 rounded-xl p-6 text-white"
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300, damping: 20 }}
            >
              <h5 className="text-2xl font-bold mb-2">{card.id}. {card.title}</h5>
              <p className="text-base">{card.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Features;