// constants.js
const name = "Learnrithm Ai Teacher";
const company = "Learnrithm Ai";
const logo =
  "https://firebasestorage.googleapis.com/v0/b/image-a202a.appspot.com/o/Logomark.png?alt=media&token=2afd4d1e-89f0-4dfe-a10a-2eddbed348cb";

const websiteURL = "https://aiteacher.learnrithm.com";
const serverURL = "https://aiteacher.learnrithm.com";
const partnerServer = "https://partner.learnrithm.com";
const quizURL = "https://quiz.learnrithm.com";

const paystackEnabled = true;
const flutterwaveEnabled = true;

const FreeType = "Free Plan";

const FreeCost = 0;
const FreeTime = "";

const MonthType = "Monthly Plan";
const DiscountMonth = "$100";
const MonthCost = 10;
const MonthTime = "month";

const YearType = "Yearly Plan";
const Discount = "200";
const YearCost = 50;
const YearTime = "year";
const country = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo (Brazzaville)", label: "Congo (Brazzaville)" },
  { value: "Congo (Kinshasa)", label: "Congo (Kinshasa)" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czechia", label: "Czechia" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Greece", label: "Greece" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Korea (North)", label: "Korea (North)" },
  { value: "Korea (South)", label: "Korea (South)" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine", label: "Palestine" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" }
];

const review = "The AI Teacher Taught me many things i didnt understand .";
const from = "Anam Meena Sharif";
const profession = "Student at OXFORD UNI";
const photoURL =
  "https://play-lh.googleusercontent.com/sV_ffBmBJt_je4RZHnfaCfcnL-Hy6C14Iol7H5EMj9fzI2GDOonuojdn5t9p6n9IAX8j";

const paystackPlanIdOne = "PLN_uhyew02o2ik1l9w";
const paystackPlanIdTwo = "PLN_zqbt39911gc56jm";
const amountInZarOne = "10";
const amountInZarTwo = "50";

const flutterwavePlanIdMonthly = "126644";
const flutterwavePlanIdYearly = "126645";
const amountInGBPMonthly = 10;
const amountInGBPYearly = 50;

const languages = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" },
  { value: "fr", label: "French" },
  { value: "de", label: "German" },
  { value: "it", label: "Italian" },
  { value: "pt", label: "Portuguese" },
  { value: "nl", label: "Dutch" },
  { value: "ru", label: "Russian" },
  { value: "zh", label: "Chinese" },
  { value: "ja", label: "Japanese" },
  { value: "ko", label: "Korean" },
  { value: "ar", label: "Arabic" },
  { value: "hi", label: "Hindi" },
  { value: "sv", label: "Swedish" },
  { value: "no", label: "Norwegian" },
  { value: "da", label: "Danish" },
  { value: "fi", label: "Finnish" },
  { value: "pl", label: "Polish" },
  { value: "tr", label: "Turkish" },
  { value: "cs", label: "Czech" },
  { value: "hu", label: "Hungarian" },
  { value: "ro", label: "Romanian" },
  { value: "el", label: "Greek" },
  { value: "he", label: "Hebrew" },
  { value: "th", label: "Thai" },
  { value: "vi", label: "Vietnamese" },
];
const level = [
  { value: "Kindergarten School", label: "Kindergarten School" },
  { value: "Nursery School", label: "Nursery" },
  { value: "Elementary School", label: "Elementary School" },
  { value: "High School", label: "High School" },
  { value: "University College", label: "University College" },
  { value: "I want to Learn A Skill", label: "I want to Learn A Skill" },
];
const curriculums = [
  { value: "Afghan National Curriculum", label: "Afghan National Curriculum" },
  {
    value: "Albanian National Curriculum",
    label: "Albanian National Curriculum",
  },
  {
    value: "Algerian National Curriculum",
    label: "Algerian National Curriculum",
  },
  {
    value: "American Common Core Standards",
    label: "American Common Core Standards",
  },
  {
    value: "Angolan National Curriculum",
    label: "Angolan National Curriculum",
  },
  {
    value: "Argentinian National Curriculum",
    label: "Argentinian National Curriculum",
  },
  {
    value: "Armenian National Curriculum",
    label: "Armenian National Curriculum",
  },
  {
    value: "Australian National Curriculum",
    label: "Australian National Curriculum",
  },
  {
    value: "Austrian National Curriculum",
    label: "Austrian National Curriculum",
  },
  {
    value: "Azerbaijani National Curriculum",
    label: "Azerbaijani National Curriculum",
  },
  {
    value: "Bahaman National Curriculum",
    label: "Bahaman National Curriculum",
  },
  {
    value: "Bangladeshi National Curriculum",
    label: "Bangladeshi National Curriculum",
  },
  {
    value: "Barbadian National Curriculum",
    label: "Barbadian National Curriculum",
  },
  {
    value: "Belgian National Curriculum",
    label: "Belgian National Curriculum",
  },
  {
    value: "Bhutanese National Curriculum",
    label: "Bhutanese National Curriculum",
  },
  {
    value: "Bolivian National Curriculum",
    label: "Bolivian National Curriculum",
  },
  {
    value: "Bosnian National Curriculum",
    label: "Bosnian National Curriculum",
  },
  {
    value: "Botswana National Curriculum",
    label: "Botswana National Curriculum",
  },
  {
    value: "Brazilian National Curriculum (BNCC)",
    label: "Brazilian National Curriculum (BNCC)",
  },
  {
    value: "British National Curriculum (England and Wales)",
    label: "British National Curriculum (England and Wales)",
  },
  {
    value: "Bulgarian National Curriculum",
    label: "Bulgarian National Curriculum",
  },
  {
    value: "Cambridge International Curriculum",
    label: "Cambridge International Curriculum",
  },
  {
    value: "Canadian Provincial Curriculums",
    label: "Canadian Provincial Curriculums",
  },
  {
    value: "Chilean National Curriculum",
    label: "Chilean National Curriculum",
  },
  {
    value: "Chinese National Curriculum",
    label: "Chinese National Curriculum",
  },
  {
    value: "Colombian National Curriculum",
    label: "Colombian National Curriculum",
  },
  {
    value: "Costa Rican National Curriculum",
    label: "Costa Rican National Curriculum",
  },
  {
    value: "Croatian National Curriculum",
    label: "Croatian National Curriculum",
  },
  { value: "Cuban National Curriculum", label: "Cuban National Curriculum" },
  { value: "Czech National Curriculum", label: "Czech National Curriculum" },
  { value: "Danish National Curriculum", label: "Danish National Curriculum" },
  { value: "Dutch National Curriculum", label: "Dutch National Curriculum" },
  {
    value: "Ecuadorian National Curriculum",
    label: "Ecuadorian National Curriculum",
  },
  {
    value: "Egyptian National Curriculum",
    label: "Egyptian National Curriculum",
  },
  {
    value: "El Salvador National Curriculum",
    label: "El Salvador National Curriculum",
  },
  {
    value: "Ethiopian National Curriculum",
    label: "Ethiopian National Curriculum",
  },
  { value: "Fijian National Curriculum", label: "Fijian National Curriculum" },
  {
    value: "Filipino National Curriculum (K-12)",
    label: "Filipino National Curriculum (K-12)",
  },
  {
    value: "Finnish National Curriculum",
    label: "Finnish National Curriculum",
  },
  {
    value: "French National Curriculum (Éducation nationale)",
    label: "French National Curriculum (Éducation nationale)",
  },
  {
    value: "Georgian National Curriculum",
    label: "Georgian National Curriculum",
  },
  {
    value: "German National Curriculum (Abitur)",
    label: "German National Curriculum (Abitur)",
  },
  {
    value: "Ghanaian National Curriculum",
    label: "Ghanaian National Curriculum",
  },
  {
    value: "Greenlandic National Curriculum",
    label: "Greenlandic National Curriculum",
  },
  { value: "Greek National Curriculum", label: "Greek National Curriculum" },
  {
    value: "Guatemalan National Curriculum",
    label: "Guatemalan National Curriculum",
  },
  {
    value: "Haitian National Curriculum",
    label: "Haitian National Curriculum",
  },
  {
    value: "Honduran National Curriculum",
    label: "Honduran National Curriculum",
  },
  {
    value: "Hungarian National Curriculum",
    label: "Hungarian National Curriculum",
  },
  {
    value: "Icelandic National Curriculum",
    label: "Icelandic National Curriculum",
  },
  {
    value: "Indian National Curriculum (CBSE, ICSE, State Boards)",
    label: "Indian National Curriculum (CBSE, ICSE, State Boards)",
  },
  {
    value: "Indonesian National Curriculum",
    label: "Indonesian National Curriculum",
  },
  {
    value: "International Baccalaureate (IB)",
    label: "International Baccalaureate (IB)",
  },
  {
    value: "Iranian National Curriculum",
    label: "Iranian National Curriculum",
  },
  { value: "Iraqi National Curriculum", label: "Iraqi National Curriculum" },
  { value: "Irish National Curriculum", label: "Irish National Curriculum" },
  {
    value: "Italian National Curriculum",
    label: "Italian National Curriculum",
  },
  {
    value: "Japanese National Curriculum",
    label: "Japanese National Curriculum",
  },
  {
    value: "Jordanian National Curriculum",
    label: "Jordanian National Curriculum",
  },
  { value: "Kazakh National Curriculum", label: "Kazakh National Curriculum" },
  {
    value: "Kenyan National Curriculum (CBC)",
    label: "Kenyan National Curriculum (CBC)",
  },
  {
    value: "Korean National Curriculum (North)",
    label: "Korean National Curriculum (North)",
  },
  {
    value: "Korean National Curriculum (South)",
    label: "Korean National Curriculum (South)",
  },
  {
    value: "Kuwaiti National Curriculum",
    label: "Kuwaiti National Curriculum",
  },
  { value: "Kyrgyz National Curriculum", label: "Kyrgyz National Curriculum" },
  {
    value: "Lebanese National Curriculum",
    label: "Lebanese National Curriculum",
  },
  { value: "Libyan National Curriculum", label: "Libyan National Curriculum" },
  {
    value: "Luxembourg National Curriculum",
    label: "Luxembourg National Curriculum",
  },
  {
    value: "Macedonian National Curriculum",
    label: "Macedonian National Curriculum",
  },
  {
    value: "Malawian National Curriculum",
    label: "Malawian National Curriculum",
  },
  {
    value: "Malaysian National Curriculum",
    label: "Malaysian National Curriculum",
  },
  {
    value: "Mauritian National Curriculum",
    label: "Mauritian National Curriculum",
  },
  {
    value: "Mexican National Curriculum",
    label: "Mexican National Curriculum",
  },
  {
    value: "Mongolian National Curriculum",
    label: "Mongolian National Curriculum",
  },
  {
    value: "Montenegrin National Curriculum",
    label: "Montenegrin National Curriculum",
  },
  {
    value: "Moroccan National Curriculum",
    label: "Moroccan National Curriculum",
  },
  {
    value: "Mozambican National Curriculum",
    label: "Mozambican National Curriculum",
  },
  {
    value: "Nepalese National Curriculum",
    label: "Nepalese National Curriculum",
  },
  {
    value: "New Zealand National Curriculum",
    label: "New Zealand National Curriculum",
  },
  {
    value: "Nigerian National Curriculum",
    label: "Nigerian National Curriculum",
  },
  {
    value: "Norwegian National Curriculum",
    label: "Norwegian National Curriculum",
  },
  {
    value: "Pakistani National Curriculum",
    label: "Pakistani National Curriculum",
  },
  {
    value: "Panamanian National Curriculum",
    label: "Panamanian National Curriculum",
  },
  {
    value: "Papua New Guinea National Curriculum",
    label: "Papua New Guinea National Curriculum",
  },
  {
    value: "Peruvian National Curriculum",
    label: "Peruvian National Curriculum",
  },
  { value: "Polish National Curriculum", label: "Polish National Curriculum" },
  {
    value: "Portuguese National Curriculum",
    label: "Portuguese National Curriculum",
  },
  { value: "Qatar National Curriculum", label: "Qatar National Curriculum" },
  {
    value: "Romanian National Curriculum",
    label: "Romanian National Curriculum",
  },
  {
    value: "Russian National Curriculum",
    label: "Russian National Curriculum",
  },
  {
    value: "Rwandan National Curriculum",
    label: "Rwandan National Curriculum",
  },
  {
    value: "Saudi Arabian National Curriculum",
    label: "Saudi Arabian National Curriculum",
  },
  {
    value: "Scottish Curriculum for Excellence",
    label: "Scottish Curriculum for Excellence",
  },
  {
    value: "Serbian National Curriculum",
    label: "Serbian National Curriculum",
  },
  {
    value: "Singaporean National Curriculum",
    label: "Singaporean National Curriculum",
  },
  { value: "Slovak National Curriculum", label: "Slovak National Curriculum" },
  {
    value: "Slovenian National Curriculum",
    label: "Slovenian National Curriculum",
  },
  { value: "Somali National Curriculum", label: "Somali National Curriculum" },
  {
    value: "South African National Curriculum (CAPS)",
    label: "South African National Curriculum (CAPS)",
  },
  {
    value: "Spanish National Curriculum",
    label: "Spanish National Curriculum",
  },
  {
    value: "Sri Lankan National Curriculum",
    label: "Sri Lankan National Curriculum",
  },
  {
    value: "Sudanese National Curriculum",
    label: "Sudanese National Curriculum",
  },
  {
    value: "Swedish National Curriculum",
    label: "Swedish National Curriculum",
  },
  { value: "Swiss National Curriculum", label: "Swiss National Curriculum" },
  { value: "Syrian National Curriculum", label: "Syrian National Curriculum" },
  {
    value: "Taiwanese National Curriculum",
    label: "Taiwanese National Curriculum",
  },
  { value: "Tajik National Curriculum", label: "Tajik National Curriculum" },
  {
    value: "Tanzanian National Curriculum",
    label: "Tanzanian National Curriculum",
  },
  { value: "Thai National Curriculum", label: "Thai National Curriculum" },
  {
    value: "Trinidad and Tobago National Curriculum",
    label: "Trinidad and Tobago National Curriculum",
  },
  {
    value: "Tunisian National Curriculum",
    label: "Tunisian National Curriculum",
  },
  {
    value: "Turkish National Curriculum",
    label: "Turkish National Curriculum",
  },
  {
    value: "Ugandan National Curriculum",
    label: "Ugandan National Curriculum",
  },
  {
    value: "Ukrainian National Curriculum",
    label: "Ukrainian National Curriculum",
  },
  {
    value: "Uruguayan National Curriculum",
    label: "Uruguayan National Curriculum",
  },
  { value: "Uzbek National Curriculum", label: "Uzbek National Curriculum" },
  {
    value: "Vietnamese National Curriculum",
    label: "Vietnamese National Curriculum",
  },
  { value: "Welsh National Curriculum", label: "Welsh National Curriculum" },
  {
    value: "Zambian National Curriculum",
    label: "Zambian National Curriculum",
  },
  {
    value: "Zimbabwean National Curriculum",
    label: "Zimbabwean National Curriculum",
  },
];

const countries = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo (Congo-Brazzaville)", label: "Congo (Congo-Brazzaville)" },
  { value: "Congo (DRC)", label: "Congo (DRC)" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Greece", label: "Greece" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "North Korea", label: "North Korea" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Korea", label: "South Korea" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Vatican City", label: "Vatican City" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

const universities = {
  Nigeria: [
    { value: "university-of-lagos", label: "University of Lagos" },
    {
      value: "obafemi-awolowo-university",
      label: "Obafemi Awolowo University",
    },
    { value: "university-of-ibadan", label: "University of Ibadan" },
    { value: "covenant-university", label: "Covenant University" },
    { value: "university-of-nigeria", label: "University of Nigeria, Nsukka" },
    { value: "ahmadu-bello-university", label: "Ahmadu Bello University" },
    { value: "university-of-benin", label: "University of Benin" },
    {
      value: "university-of-port-harcourt",
      label: "University of Port Harcourt",
    },
    { value: "nnamdi-azikiwe-university", label: "Nnamdi Azikiwe University" },
    { value: "university-of-jos", label: "University of Jos" },
    {
      value: "federal-university-of-technology-akure",
      label: "Federal University of Technology, Akure",
    },
    {
      value: "federal-university-of-technology-minna",
      label: "Federal University of Technology, Minna",
    },
    {
      value: "federal-university-of-technology-owerri",
      label: "Federal University of Technology, Owerri",
    },
    { value: "lagos-state-university", label: "Lagos State University" },
    { value: "bayero-university-kano", label: "Bayero University Kano" },
    { value: "university-of-abuja", label: "University of Abuja" },
    { value: "university-of-calabar", label: "University of Calabar" },
    {
      value: "ladoke-akintola-university-of-technology",
      label: "Ladoke Akintola University of Technology",
    },
    { value: "ekiti-state-university", label: "Ekiti State University" },
    { value: "university-of-ilorin", label: "University of Ilorin" },
    {
      value: "federal-university-of-petroleum-resources-effurun",
      label: "Federal University of Petroleum Resources, Effurun",
    },
    { value: "federal-university-dutse", label: "Federal University, Dutse" },
    { value: "federal-university-gashua", label: "Federal University, Gashua" },
    {
      value: "federal-university-dutsin-ma",
      label: "Federal University, Dutsin-Ma",
    },
    { value: "federal-university-lokoja", label: "Federal University, Lokoja" },
    {
      value: "federal-university-kashere",
      label: "Federal University, Kashere",
    },
    { value: "federal-university-wukari", label: "Federal University, Wukari" },
    { value: "federal-university-otuoke", label: "Federal University, Otuoke" },
    {
      value: "federal-university-birnin-kebbi",
      label: "Federal University, Birnin Kebbi",
    },
    { value: "federal-university-gusau", label: "Federal University, Gusau" },
    {
      value: "national-open-university-of-nigeria",
      label: "National Open University of Nigeria",
    },
    {
      value: "nigerian-defence-academy",
      label: "Nigerian Defence Academy, Kaduna",
    },
    {
      value: "nigerian-police-academy-wudil",
      label: "Nigerian Police Academy, Wudil",
    },
    {
      value: "air-force-institute-of-technology",
      label: "Air Force Institute of Technology, Kaduna",
    },
    {
      value: "nile-university-of-nigeria",
      label: "Nile University of Nigeria",
    },
    { value: "baze-university", label: "Baze University, Abuja" },
    { value: "veritas-university", label: "Veritas University, Abuja" },
    {
      value: "american-university-of-nigeria",
      label: "American University of Nigeria",
    },
    { value: "babcock-university", label: "Babcock University, Ilishan-Remo" },
    { value: "adeleke-university", label: "Adeleke University, Ede" },
    { value: "bowen-university", label: "Bowen University, Iwo" },
    { value: "redeemers-university", label: "Redeemer’s University, Ede" },
    { value: "madonna-university", label: "Madonna University, Elele" },
    {
      value: "igbinedion-university-okada",
      label: "Igbinedion University, Okada",
    },
    { value: "aunty-audu-polytechnic", label: "Aunty Audu Polytechnic, Kano" },
    { value: "achievers-university", label: "Achievers University, Owo" },
    { value: "al-hikmah-university", label: "Al-Hikmah University, Ilorin" },
    { value: "caritas-university", label: "Caritas University, Enugu" },
    { value: "crawford-university", label: "Crawford University, Igbesa" },
    { value: "landmark-university", label: "Landmark University, Omu-Aran" },
    { value: "lead-city-university", label: "Lead City University, Ibadan" },
    { value: "novena-university", label: "Novena University, Ogume" },
    {
      value: "pan-atlantic-university",
      label: "Pan-Atlantic University, Lagos",
    },
    { value: "paul-university", label: "Paul University, Awka" },
    { value: "rhema-university", label: "Rhema University, Aba" },
    { value: "salem-university", label: "Salem University, Lokoja" },
    { value: "tansian-university", label: "Tansian University, Umunya" },
    { value: "wesley-university", label: "Wesley University, Ondo" },
    {
      value: "yusuf-maitama-sule-university",
      label: "Yusuf Maitama Sule University, Kano",
    },
    {
      value: "rivers-state-university",
      label: "Rivers State University, Port Harcourt",
    },
    { value: "osun-state-university", label: "Osun State University, Osogbo" },
    {
      value: "kwara-state-university",
      label: "Kwara State University, Malete",
    },
    {
      value: "benue-state-university",
      label: "Benue State University, Makurdi",
    },
    {
      value: "kaduna-state-university",
      label: "Kaduna State University, Kaduna",
    },
    { value: "gombe-state-university", label: "Gombe State University, Gombe" },
    {
      value: "nasarawa-state-university",
      label: "Nasarawa State University, Keffi",
    },
    {
      value: "bauchi-state-university",
      label: "Bauchi State University, Gadau",
    },
    {
      value: "cross-river-university-of-technology",
      label: "Cross River University of Technology, Calabar",
    },
    {
      value: "enugu-state-university-of-science-and-technology",
      label: "Enugu State University of Science and Technology, Enugu",
    },
    { value: "edo-state-university", label: "Edo State University, Uzairue" },
    { value: "imo-state-university", label: "Imo State University, Owerri" },
    { value: "kogi-state-university", label: "Kogi State University, Anyigba" },
    {
      value: "ebonyi-state-university",
      label: "Ebonyi State University, Abakaliki",
    },
    {
      value: "technical-university-ibadan",
      label: "The Technical University, Ibadan",
    },
  ],
  "United States": [
    { value: "harvard-university", label: "Harvard University" },
    { value: "stanford-university", label: "Stanford University" },
    {
      value: "massachusetts-institute-of-technology",
      label: "Massachusetts Institute of Technology (MIT)",
    },
    {
      value: "university-of-california-berkeley",
      label: "University of California, Berkeley",
    },
    { value: "university-of-chicago", label: "University of Chicago" },
    { value: "columbia-university", label: "Columbia University" },
    { value: "princeton-university", label: "Princeton University" },
    { value: "yale-university", label: "Yale University" },
    {
      value: "california-institute-of-technology",
      label: "California Institute of Technology (Caltech)",
    },
    {
      value: "university-of-pennsylvania",
      label: "University of Pennsylvania",
    },
    { value: "cornell-university", label: "Cornell University" },
    { value: "duke-university", label: "Duke University" },
    { value: "university-of-michigan", label: "University of Michigan" },
    { value: "new-york-university", label: "New York University (NYU)" },
    {
      value: "university-of-california-los-angeles",
      label: "University of California, Los Angeles (UCLA)",
    },
    {
      value: "university-of-southern-california",
      label: "University of Southern California (USC)",
    },
    {
      value: "university-of-texas-at-austin",
      label: "University of Texas at Austin",
    },
    { value: "university-of-washington", label: "University of Washington" },
    {
      value: "university-of-wisconsin-madison",
      label: "University of Wisconsin-Madison",
    },
    { value: "university-of-florida", label: "University of Florida" },
    { value: "georgetown-university", label: "Georgetown University" },
    { value: "brown-university", label: "Brown University" },
    { value: "northwestern-university", label: "Northwestern University" },
    {
      value: "university-of-illinois-at-urbana-champaign",
      label: "University of Illinois at Urbana-Champaign",
    },
    { value: "university-of-virginia", label: "University of Virginia" },
    { value: "vanderbilt-university", label: "Vanderbilt University" },
    {
      value: "university-of-north-carolina-at-chapel-hill",
      label: "University of North Carolina at Chapel Hill",
    },
    {
      value: "pennsylvania-state-university",
      label: "Pennsylvania State University",
    },
    { value: "texas-am-university", label: "Texas A&M University" },
    {
      value: "carnegie-mellon-university",
      label: "Carnegie Mellon University",
    },
    { value: "rice-university", label: "Rice University" },
    { value: "boston-university", label: "Boston University" },
    { value: "johns-hopkins-university", label: "Johns Hopkins University" },
    {
      value: "university-of-california-san-diego",
      label: "University of California, San Diego",
    },
    {
      value: "university-of-california-santa-barbara",
      label: "University of California, Santa Barbara",
    },
    {
      value: "georgia-institute-of-technology",
      label: "Georgia Institute of Technology",
    },
    { value: "university-of-minnesota", label: "University of Minnesota" },
    { value: "ohio-state-university", label: "Ohio State University" },
    { value: "university-of-miami", label: "University of Miami" },
    {
      value: "indiana-university-bloomington",
      label: "Indiana University Bloomington",
    },
    { value: "university-of-arizona", label: "University of Arizona" },
    {
      value: "university-of-colorado-boulder",
      label: "University of Colorado Boulder",
    },
    { value: "university-of-pittsburgh", label: "University of Pittsburgh" },
    {
      value: "washington-university-in-st-louis",
      label: "Washington University in St. Louis",
    },
    { value: "rutgers-university", label: "Rutgers University" },
    { value: "university-of-georgia", label: "University of Georgia" },
    {
      value: "university-of-california-irvine",
      label: "University of California, Irvine",
    },
    { value: "michigan-state-university", label: "Michigan State University" },
    {
      value: "university-of-maryland-college-park",
      label: "University of Maryland, College Park",
    },
    { value: "brigham-young-university", label: "Brigham Young University" },
    { value: "university-of-iowa", label: "University of Iowa" },
    { value: "university-of-kansas", label: "University of Kansas" },
    { value: "university-of-notre-dame", label: "University of Notre Dame" },
    { value: "university-of-houston", label: "University of Houston" },
    { value: "arizona-state-university", label: "Arizona State University" },
    {
      value: "university-of-nebraska-lincoln",
      label: "University of Nebraska-Lincoln",
    },
    {
      value: "southern-methodist-university",
      label: "Southern Methodist University",
    },
    { value: "emory-university", label: "Emory University" },
    { value: "pepperdine-university", label: "Pepperdine University" },
    { value: "syracuse-university", label: "Syracuse University" },
    { value: "university-of-oregon", label: "University of Oregon" },
    { value: "university-of-utah", label: "University of Utah" },
    { value: "tulane-university", label: "Tulane University" },
    { value: "colorado-state-university", label: "Colorado State University" },
    { value: "university-of-vermont", label: "University of Vermont" },
    {
      value: "stevens-institute-of-technology",
      label: "Stevens Institute of Technology",
    },
    { value: "fordham-university", label: "Fordham University" },
    { value: "clark-university", label: "Clark University" },
    { value: "american-university", label: "American University" },
    { value: "drexel-university", label: "Drexel University" },
    { value: "university-of-san-diego", label: "University of San Diego" },
    {
      value: "san-diego-state-university",
      label: "San Diego State University",
    },
    { value: "loyola-university-chicago", label: "Loyola University Chicago" },
    { value: "tufts-university", label: "Tufts University" },
  ],
  "United Kingdom": [
    { value: "university-of-oxford", label: "University of Oxford" },
    { value: "university-of-cambridge", label: "University of Cambridge" },
    { value: "imperial-college-london", label: "Imperial College London" },
    {
      value: "london-school-of-economics",
      label: "London School of Economics and Political Science (LSE)",
    },
    {
      value: "university-college-london",
      label: "University College London (UCL)",
    },
    { value: "university-of-edinburgh", label: "University of Edinburgh" },
    { value: "university-of-manchester", label: "University of Manchester" },
    { value: "university-of-glasgow", label: "University of Glasgow" },
    { value: "king's-college-london", label: "King's College London (KCL)" },
    { value: "university-of-bristol", label: "University of Bristol" },
    { value: "university-of-warwick", label: "University of Warwick" },
    { value: "university-of-leeds", label: "University of Leeds" },
    { value: "university-of-sheffield", label: "University of Sheffield" },
    { value: "university-of-birmingham", label: "University of Birmingham" },
    { value: "durham-university", label: "Durham University" },
    { value: "university-of-southampton", label: "University of Southampton" },
    { value: "university-of-york", label: "University of York" },
    { value: "university-of-liverpool", label: "University of Liverpool" },
    { value: "university-of-st-andrews", label: "University of St Andrews" },
    { value: "university-of-aberdeen", label: "University of Aberdeen" },
    { value: "university-of-strathclyde", label: "University of Strathclyde" },
    { value: "university-of-dundee", label: "University of Dundee" },
    { value: "university-of-bath", label: "University of Bath" },
    { value: "cardiff-university", label: "Cardiff University" },
    { value: "university-of-exeter", label: "University of Exeter" },
    { value: "university-of-reading", label: "University of Reading" },
    { value: "newcastle-university", label: "Newcastle University" },
    { value: "university-of-nottingham", label: "University of Nottingham" },
    {
      value: "queen-mary-university-of-london",
      label: "Queen Mary University of London",
    },
    { value: "lancaster-university", label: "Lancaster University" },
    { value: "university-of-leicester", label: "University of Leicester" },
    { value: "university-of-surrey", label: "University of Surrey" },
    { value: "university-of-east-anglia", label: "University of East Anglia" },
    { value: "university-of-kent", label: "University of Kent" },
    { value: "soas-university-of-london", label: "SOAS University of London" },
    { value: "city-university-of-london", label: "City, University of London" },
    { value: "university-of-hull", label: "University of Hull" },
    { value: "university-of-portsmouth", label: "University of Portsmouth" },
    { value: "university-of-sussex", label: "University of Sussex" },
    { value: "university-of-westminster", label: "University of Westminster" },
    { value: "brunel-university-london", label: "Brunel University London" },
    { value: "university-of-essex", label: "University of Essex" },
    { value: "oxford-brookes-university", label: "Oxford Brookes University" },
    { value: "university-of-plymouth", label: "University of Plymouth" },
    { value: "university-of-lincoln", label: "University of Lincoln" },
    { value: "aberystwyth-university", label: "Aberystwyth University" },
    { value: "bangor-university", label: "Bangor University" },
    {
      value: "university-of-wolverhampton",
      label: "University of Wolverhampton",
    },
    {
      value: "university-of-central-lancashire",
      label: "University of Central Lancashire",
    },
    {
      value: "sheffield-hallam-university",
      label: "Sheffield Hallam University",
    },
    {
      value: "manchester-metropolitan-university",
      label: "Manchester Metropolitan University",
    },
    {
      value: "university-of-west-of-scotland",
      label: "University of the West of Scotland",
    },
    {
      value: "glasgow-caledonian-university",
      label: "Glasgow Caledonian University",
    },
    { value: "university-of-greenwich", label: "University of Greenwich" },
    {
      value: "university-of-hertfordshire",
      label: "University of Hertfordshire",
    },
    { value: "university-of-south-wales", label: "University of South Wales" },
    { value: "middlesex-university", label: "Middlesex University" },
    { value: "bournemouth-university", label: "Bournemouth University" },
    { value: "university-of-chester", label: "University of Chester" },
    { value: "university-of-brighton", label: "University of Brighton" },
    {
      value: "london-metropolitan-university",
      label: "London Metropolitan University",
    },
    { value: "university-of-west-london", label: "University of West London" },
    { value: "university-of-roehampton", label: "University of Roehampton" },
  ],
  Canada: [
    { value: "university-of-toronto", label: "University of Toronto" },
    {
      value: "university-of-british-columbia",
      label: "University of British Columbia (UBC)",
    },
    { value: "mcgill-university", label: "McGill University" },
    { value: "university-of-alberta", label: "University of Alberta" },
    { value: "university-of-manitoba", label: "University of Manitoba" },
    { value: "university-of-waterloo", label: "University of Waterloo" },
    { value: "western-university", label: "Western University" },
    { value: "university-of-ottawa", label: "University of Ottawa" },
    { value: "queen-s-university", label: "Queen’s University" },
    { value: "dalhousie-university", label: "Dalhousie University" },
    { value: "university-of-nova-scotia", label: "University of Nova Scotia" },
    { value: "carleton-university", label: "Carleton University" },
    {
      value: "university-of-saskatchewan",
      label: "University of Saskatchewan",
    },
    { value: "ryerson-university", label: "Ryerson University" },
    {
      value: "university-of-new-brunswick",
      label: "University of New Brunswick",
    },
    { value: "lakehead-university", label: "Lakehead University" },
    {
      value: "university-of-prince-edward-island",
      label: "University of Prince Edward Island",
    },
    { value: "university-of-guelph", label: "University of Guelph" },
    { value: "university-of-canada-west", label: "University of Canada West" },
    { value: "universite-de-montreal", label: "Université de Montréal" },
    { value: "universite-laval", label: "Université Laval" },
    { value: "universite-du-quebec", label: "Université du Québec" },
    { value: "university-of-windsor", label: "University of Windsor" },
    { value: "university-of-ryerson", label: "Ryerson University" },
  ],
  Australia: [
    { value: "university-of-sydney", label: "University of Sydney" },
    { value: "university-of-melbourne", label: "University of Melbourne" },
    {
      value: "australian-national-university",
      label: "Australian National University",
    },
    { value: "university-of-queensland", label: "University of Queensland" },
    {
      value: "university-of-new-south-wales",
      label: "University of New South Wales",
    },
    { value: "university-of-adelaide", label: "University of Adelaide" },
    { value: "monash-university", label: "Monash University" },
    {
      value: "university-of-western-australia",
      label: "University of Western Australia",
    },
    { value: "university-of-canberra", label: "University of Canberra" },
    {
      value: "university-of-southern-queensland",
      label: "University of Southern Queensland",
    },
    { value: "macquarie-university", label: "Macquarie University" },
    { value: "flinders-university", label: "Flinders University" },
    { value: "rmit-university", label: "RMIT University" },
    { value: "la-trobe-university", label: "La Trobe University" },
    { value: "university-of-tasmania", label: "University of Tasmania" },
    { value: "curtin-university", label: "Curtin University" },
    { value: "deakin-university", label: "Deakin University" },
    { value: "griffith-university", label: "Griffith University" },
    {
      value: "queensland-university-of-technology",
      label: "Queensland University of Technology",
    },
    { value: "university-of-new-england", label: "University of New England" },
    {
      value: "university-of-south-australia",
      label: "University of South Australia",
    },
    {
      value: "central-queensland-university",
      label: "Central Queensland University",
    },
    {
      value: "university-of-technology-sydney",
      label: "University of Technology Sydney",
    },
    { value: "western-sydney-university", label: "Western Sydney University" },
  ],
  Germany: [
    { value: "university-of-munich", label: "University of Munich (LMU)" },
    {
      value: "technical-university-of-munich",
      label: "Technical University of Munich (TUM)",
    },
    { value: "heidelberg-university", label: "Heidelberg University" },
    {
      value: "goethe-university-frankfurt",
      label: "Goethe University Frankfurt",
    },
    { value: "university-of-berlin", label: "Humboldt University of Berlin" },
    { value: "free-university-of-berlin", label: "Free University of Berlin" },
    { value: "university-of-gottingen", label: "University of Göttingen" },
    { value: "university-of-stuttgart", label: "University of Stuttgart" },
    {
      value: "technical-university-of-darmstadt",
      label: "Technical University of Darmstadt",
    },
    { value: "university-of-tubingen", label: "University of Tübingen" },
    { value: "university-of-hamburg", label: "University of Hamburg" },
    { value: "university-of-dusseldorf", label: "University of Düsseldorf" },
    { value: "university-of-karlsruhe", label: "University of Karlsruhe" },
    { value: "university-of-frankfurt", label: "University of Frankfurt" },
    { value: "university-of-bremen", label: "University of Bremen" },
    { value: "university-of-leipzig", label: "University of Leipzig" },
    { value: "university-of-wurzburg", label: "University of Würzburg" },
    { value: "university-of-kiel", label: "University of Kiel" },
    { value: "university-of-potsdam", label: "University of Potsdam" },
    { value: "university-of-magdeburg", label: "University of Magdeburg" },
    { value: "university-of-giessen", label: "University of Giessen" },
    { value: "university-of-erfurt", label: "University of Erfurt" },
    { value: "university-of-augustana", label: "University of Augsburg" },
  ],
  France: [
    { value: "university-of-paris", label: "University of Paris" },
    {
      value: "ecole-normale-superieure",
      label: "École Normale Supérieure (ENS)",
    },
    {
      value: "university-of-grenoble-alpes",
      label: "University of Grenoble Alpes",
    },
    { value: "universite-paris-sorbonne", label: "Université Paris-Sorbonne" },
    { value: "universite-paris-diderot", label: "Université Paris-Diderot" },
    { value: "university-of-lyon", label: "University of Lyon" },
    {
      value: "universite-paul-valery",
      label: "Université Paul Valéry Montpellier 3",
    },
    { value: "university-of-marseille", label: "University of Marseille" },
    { value: "university-of-rouen", label: "University of Rouen" },
    {
      value: "universite-de-reims",
      label: "Université de Reims Champagne-Ardenne",
    },
    {
      value: "universite-d-aix-marseille",
      label: "Université d’Aix-Marseille",
    },
    { value: "universite-de-toulouse", label: "Université de Toulouse" },
    { value: "universite-de-bordeaux", label: "Université de Bordeaux" },
    { value: "universite-de-nantes", label: "Université de Nantes" },
    { value: "universite-de-lille", label: "Université de Lille" },
    {
      value: "universite-de-clermont-ferrand",
      label: "Université Clermont Auvergne",
    },
    {
      value: "universite-de-cergy-pontoise",
      label: "Université de Cergy-Pontoise",
    },
    { value: "universite-de-bourgogne", label: "Université de Bourgogne" },
    { value: "universite-de-lorraine", label: "Université de Lorraine" },
    { value: "universite-de-nice", label: "Université de Nice" },
    { value: "universite-de-poitiers", label: "Université de Poitiers" },
  ],
  China: [
    { value: "tsinghua-university", label: "Tsinghua University" },
    { value: "peking-university", label: "Peking University" },
    { value: "fudan-university", label: "Fudan University" },
    {
      value: "shanghai-jiao-tong-university",
      label: "Shanghai Jiao Tong University",
    },
    { value: "zhejiang-university", label: "Zhejiang University" },
    {
      value: "university-of-china",
      label: "University of Chinese Academy of Sciences",
    },
    { value: "nanjing-university", label: "Nanjing University" },
    { value: "sun-yat-sen-university", label: "Sun Yat-sen University" },
    { value: "beijing-normal-university", label: "Beijing Normal University" },
    {
      value: "renmin-university-of-china",
      label: "Renmin University of China",
    },
    {
      value: "harbin-institute-of-technology",
      label: "Harbin Institute of Technology",
    },
    {
      value: "south-china-university-of-technology",
      label: "South China University of Technology",
    },
    {
      value: "northeast-normal-university",
      label: "Northeast Normal University",
    },
    { value: "xiamen-university", label: "Xiamen University" },
    { value: "chongqing-university", label: "Chongqing University" },
    { value: "beihang-university", label: "Beihang University" },
    { value: "southeast-university", label: "Southeast University" },
    {
      value: "northwestern-university-china",
      label: "Northwestern University (China)",
    },
    { value: "shanghai-university", label: "Shanghai University" },
    { value: "jiangsu-university", label: "Jiangsu University" },
  ],
  Italy: [
    { value: "university-of-rome", label: "University of Rome La Sapienza" },
    { value: "university-of-bologna", label: "University of Bologna" },
    { value: "university-of-milan", label: "University of Milan" },
    { value: "university-of-turin", label: "University of Turin" },
    { value: "university-of-padua", label: "University of Padua" },
    {
      value: "university-of-naples",
      label: "University of Naples Federico II",
    },
    {
      value: "university-of-venice",
      label: "University of Venice Ca’ Foscari",
    },
    { value: "university-of-florence", label: "University of Florence" },
    { value: "university-of-siena", label: "University of Siena" },
    { value: "university-of-perugia", label: "University of Perugia" },
    { value: "university-of-bari", label: "University of Bari" },
    {
      value: "university-of-milan-bicocca",
      label: "University of Milan-Bicocca",
    },
    { value: "university-of-tor-vergata", label: "University of Tor Vergata" },
  ],
  SouthAfrica: [
    { value: "university-of-cape-town", label: "University of Cape Town" },
    {
      value: "university-of-witwatersrand",
      label: "University of the Witwatersrand",
    },
    { value: "stellenbosch-university", label: "Stellenbosch University" },
    {
      value: "university-of-kwazulu-natal",
      label: "University of KwaZulu-Natal",
    },
    {
      value: "university-of-south-africa",
      label: "University of South Africa",
    },
    {
      value: "university-of-the-free-state",
      label: "University of the Free State",
    },
    { value: "university-of-tshwane", label: "University of Pretoria" },
    { value: "rhodes-university", label: "Rhodes University" },
    { value: "university-of-natal", label: "University of Natal" },
  ],
  India: [
    {
      value: "indian-institute-of-science",
      label: "Indian Institute of Science",
    },
    {
      value: "indian-institute-of-technology-delhi",
      label: "Indian Institute of Technology Delhi",
    },
    {
      value: "indian-institute-of-technology-bombay",
      label: "Indian Institute of Technology Bombay",
    },
    {
      value: "indian-institute-of-technology-kanpur",
      label: "Indian Institute of Technology Kanpur",
    },
    {
      value: "indian-institute-of-technology-kharagpur",
      label: "Indian Institute of Technology Kharagpur",
    },
    {
      value: "indian-institute-of-technology-madras",
      label: "Indian Institute of Technology Madras",
    },
    {
      value: "indian-institute-of-technology-roorkee",
      label: "Indian Institute of Technology Roorkee",
    },
    {
      value: "indian-institute-of-technology-guwahati",
      label: "Indian Institute of Technology Guwahati",
    },
    { value: "university-of-delhi", label: "University of Delhi" },
    {
      value: "jawaharlal-nehru-university",
      label: "Jawaharlal Nehru University",
    },
    { value: "banaras-hindu-university", label: "Banaras Hindu University" },
    { value: "university-of-mumbai", label: "University of Mumbai" },
    { value: "university-of-kolkata", label: "University of Kolkata" },
    { value: "university-of-pune", label: "University of Pune" },
    { value: "punjab-university", label: "Punjab University" },
    { value: "university-of-jaipur", label: "University of Jaipur" },
    { value: "university-of-chandigarh", label: "University of Chandigarh" },
    { value: "university-of-kerala", label: "University of Kerala" },
    {
      value: "sri-venkateswara-university",
      label: "Sri Venkateswara University",
    },
    { value: "anna-university", label: "Anna University" },
    {
      value: "jawaharlal-nehru-technological-university-hyderabad",
      label: "Jawaharlal Nehru Technological University Hyderabad",
    },
    {
      value: "maulana-azad-national-institute-of-technology",
      label: "Maulana Azad National Institute of Technology",
    },
    { value: "aligarh-muslim-university", label: "Aligarh Muslim University" },
    {
      value: "national-institute-of-technology-surat",
      label: "National Institute of Technology Surat",
    },
    {
      value: "national-institute-of-technology-warangal",
      label: "National Institute of Technology Warangal",
    },
    {
      value: "national-institute-of-technology-calicut",
      label: "National Institute of Technology Calicut",
    },
    {
      value: "national-institute-of-technology-durgapur",
      label: "National Institute of Technology Durgapur",
    },
    {
      value: "national-institute-of-technology-jamshedpur",
      label: "National Institute of Technology Jamshedpur",
    },
    {
      value: "national-institute-of-technology-kurukshetra",
      label: "National Institute of Technology Kurukshetra",
    },
    { value: "jadavpur-university", label: "Jadavpur University" },
    { value: "delhi-university", label: "Delhi University" },
    { value: "university-of-bangalore", label: "University of Bangalore" },
    { value: "university-of-gauhati", label: "University of Gauhati" },
    { value: "university-of-nagpur", label: "University of Nagpur" },
    { value: "university-of-jammu", label: "University of Jammu" },
    { value: "university-of-shivaji", label: "University of Shivaji" },
    { value: "rani-durgavati-university", label: "Rani Durgavati University" },
    { value: "university-of-rajasthan", label: "University of Rajasthan" },
    {
      value: "maharaja-sayajirao-university-of-baroda",
      label: "Maharaja Sayajirao University of Baroda",
    },
    { value: "bhagwant-university", label: "Bhagwant University" },
    {
      value: "central-university-of-jharkhand",
      label: "Central University of Jharkhand",
    },
    {
      value: "central-university-of-haryana",
      label: "Central University of Haryana",
    },
    {
      value: "central-university-of-punjab",
      label: "Central University of Punjab",
    },
    {
      value: "central-university-of-kashmir",
      label: "Central University of Kashmir",
    },
    {
      value: "central-university-of-south-bihar",
      label: "Central University of South Bihar",
    },
    {
      value: "indira-gandhi-national-open-university",
      label: "Indira Gandhi National Open University",
    },
    {
      value: "northern-india-engineering-college",
      label: "Northern India Engineering College",
    },
    {
      value: "punjab-engineering-college",
      label: "Punjab Engineering College",
    },
    { value: "shivaji-university", label: "Shivaji University" },
    {
      value: "dr-babasaheb-ambedkar-marathwada-university",
      label: "Dr. Babasaheb Ambedkar Marathwada University",
    },
    {
      value: "ravi-narayan-misra-institute-of-engineering-and-technology",
      label: "Ravi Narayan Misra Institute of Engineering and Technology",
    },
    { value: "priestley-college", label: "Priestley College" },
    { value: "galgotias-university", label: "Galgotias University" },
  ],
  Afghanistan: [
    { value: "kabul-university", label: "Kabul University" },
    { value: "herat-university", label: "Herat University" },
    { value: "pajhwok-university", label: "Pajhwok University" },
    { value: "takhar-university", label: "Takhar University" },
    { value: "jalalabad-university", label: "Jalalabad University" },
    { value: "nangarhar-university", label: "Nangarhar University" },
    { value: "balkh-university", label: "Balkh University" },
    { value: "sharif-university", label: "Sharif University" },
    { value: "mazar-e-sharif-university", label: "Mazar-e-Sharif University" },
    { value: "kandahar-university", label: "Kandahar University" },
  ],
  " Albania": [
    { value: "university-of-tirana", label: "University of Tirana" },
    { value: "university-of-shkodra", label: "University of Shkodra" },
    { value: "university-of-vlora", label: "University of Vlora" },
    {
      value: "polytechnic-university-of-tirana",
      label: "Polytechnic University of Tirana",
    },
    { value: "university-of-durres", label: "University of Durres" },
    { value: "university-of-korca", label: "University of Korça" },
  ],
  Algeria: [
    { value: "university-of-algiers", label: "University of Algiers" },
    { value: "university-of-constantine", label: "University of Constantine" },
    { value: "university-of-oran", label: "University of Oran" },
    { value: "university-of-annaba", label: "University of Annaba" },
    { value: "university-of-setif", label: "University of Setif" },
    { value: "university-of-tlemcen", label: "University of Tlemcen" },
    { value: "university-of-batna", label: "University of Batna" },
    { value: "university-of-tizi-ouzou", label: "University of Tizi Ouzou" },
    { value: "university-of-oudjna", label: "University of Ouedjna" },
    { value: "university-of-medea", label: "University of Medea" },
  ],
  Andorra: [{ value: "universitat-d-andorra", label: "Universitat d'Andorra" }],
  "  Angola": [
    { value: "university-of-luanda", label: "University of Luanda" },
    { value: "agostinho-neto-university", label: "Agostinho Neto University" },
    { value: "university-of-benguela", label: "University of Benguela" },
    { value: "university-of-huambo", label: "University of Huambo" },
    { value: "university-of-lubango", label: "University of Lubango" },
    {
      value: "university-of-kuando-kubango",
      label: "University of Kuando Kubango",
    },
  ],
  AntiguaBarbuda: [
    { value: "university-of-antigua", label: "University of Antigua" },
    { value: "antigua-state-college", label: "Antigua State College" },
  ],
  Argentina: [
    {
      value: "university-of-buenos-aires",
      label: "University of Buenos Aires",
    },
    {
      value: "national-university-of-la-plata",
      label: "National University of La Plata",
    },
    {
      value: "national-university-of-cordoba",
      label: "National University of Córdoba",
    },
    {
      value: "national-university-of-rosario",
      label: "National University of Rosario",
    },
    {
      value: "national-university-of-mendoza",
      label: "National University of Mendoza",
    },
    {
      value: "national-university-of-south",
      label: "National University of the South",
    },
    { value: "university-of-tucuman", label: "University of Tucumán" },
    { value: "university-of-patagonia", label: "University of Patagonia" },
  ],
  Armenia: [
    { value: "Yerevan-State-University", label: "Yerevan State University" },
    {
      value: "American-University-of-Armenia",
      label: "American University of Armenia",
    },
    {
      value: "Armenian-State-Pedagogical-University",
      label: "Armenian State Pedagogical University",
    },
    {
      value: "National-Polytechnic-University-of-Armenia",
      label: "National Polytechnic University of Armenia",
    },
    {
      value: "Yerevan-Engineering-University",
      label: "Yerevan Engineering University",
    },
    {
      value: "Armenian-Russian-University",
      label: "Armenian-Russian University",
    },
  ],
  Austria: [
    { value: "university-of-vienna", label: "University of Vienna" },
    {
      value: "vienna-university-of-technology",
      label: "Vienna University of Technology",
    },
    { value: "university-of-salzburg", label: "University of Salzburg" },
    { value: "university-of-innsbruck", label: "University of Innsbruck" },
    { value: "university-of-graz", label: "University of Graz" },
    {
      value: "medical-university-of-vienna",
      label: "Medical University of Vienna",
    },
    {
      value: "university-of-applied-arts-vienna",
      label: "University of Applied Arts Vienna",
    },
  ],
  Azerbaijan: [
    { value: "baku-state-university", label: "Baku State University" },
    {
      value: "azerbaijan-university-of-cooperation",
      label: "Azerbaijan University of Cooperation",
    },
    {
      value: "azerbaijan-state-economics-university",
      label: "Azerbaijan State Economics University",
    },
    {
      value: "azerbaijan-medical-university",
      label: "Azerbaijan Medical University",
    },
    {
      value: "azerbaijan-university-of-architecture-and-construction",
      label: "Azerbaijan University of Architecture and Construction",
    },
    { value: "khazar-university", label: "Khazar University" },
    { value: "ganja-state-university", label: "Ganja State University" },
  ],
  "The Bahamas": [
    { value: "university-of-the-bahamas", label: "University of the Bahamas" },
    {
      value: "bahamas-technical-and-vocational-institute",
      label: "Bahamas Technical and Vocational Institute",
    },
    {
      value: "the-college-of-the-bahamas",
      label: "The College of the Bahamas",
    },
  ],
  Bahrain: [
    { value: "university-of-bahrain", label: "University of Bahrain" },
    { value: "al-azhar-university", label: "Al-Azhar University" },
    { value: "bahrain-university", label: "Bahrain University" },
    { value: "diplomatic-academy", label: "Diplomatic Academy" },
  ],
  Bangladesh: [
    { value: "university-of-dhaka", label: "University of Dhaka" },
    { value: "jahangirnagar-university", label: "Jahangirnagar University" },
    {
      value: "bangladesh-university-of-engineering-and-technology",
      label: "Bangladesh University of Engineering and Technology",
    },
    { value: "north-south-university", label: "North South University" },
    { value: "brac-university", label: "BRAC University" },
    { value: "university-of-rajshahi", label: "University of Rajshahi" },
    { value: "chittagong-university", label: "Chittagong University" },
    { value: "khulna-university", label: "Khulna University" },
    {
      value: "hajee-mohammad-danesh-science-and-technology-university",
      label: "Hajee Mohammad Danesh Science and Technology University",
    },
    {
      value: "patuakhali-science-and-technology-university",
      label: "Patuakhali Science and Technology University",
    },
  ],
  Barbados: [
    {
      value: "university-of-the-west-indies",
      label: "University of the West Indies",
    },
    {
      value: "barbados-community-college",
      label: "Barbados Community College",
    },
    { value: "university-of-barbados", label: "University of Barbados" },
  ],
  Belarus: [
    {
      value: "belarusian-state-university",
      label: "Belarusian State University",
    },
    {
      value: "belarusian-technical-university",
      label: "Belarusian Technical University",
    },
    {
      value: "belarusian-state-economic-university",
      label: "Belarusian State Economic University",
    },
    {
      value: "belarusian-state-university-of-informatics-and-radioelectronics",
      label: "Belarusian State University of Informatics and Radioelectronics",
    },
    {
      value: "belarusian-state-medical-university",
      label: "Belarusian State Medical University",
    },
  ],
  Belgium: [
    { value: "ku-leuven", label: "KU Leuven" },
    { value: "university-of-gent", label: "University of Gent" },
    { value: "university-of-antwerp", label: "University of Antwerp" },
    { value: "vub", label: "Vrije Universiteit Brussel" },
    { value: "university-of-liege", label: "University of Liège" },
    {
      value: "university-college-limburg",
      label: "University College Limburg",
    },
    {
      value: "hogeschool-voor-wetenschap-en-kunst",
      label: "Hogeschool voor Wetenschap en Kunst",
    },
  ],
  Belize: [
    { value: "university-of-belize", label: "University of Belize" },
    { value: "belize-technical-college", label: "Belize Technical College" },
  ],
  Benin: [
    {
      value: "university-of-abomey-calavi",
      label: "University of Abomey-Calavi",
    },
    { value: "university-of-dossou", label: "University of Dossou" },
    { value: "university-of-parakou", label: "University of Parakou" },
  ],
  Bhutan: [
    {
      value: "royal-university-of-bhutan",
      label: "Royal University of Bhutan",
    },
    { value: "bhutan-college", label: "Bhutan College" },
  ],
  Bolivia: [
    { value: "university-of-bolivia", label: "University of Bolivia" },
    { value: "university-of-san-andres", label: "University of San Andrés" },
    { value: "university-of-santa-cruz", label: "University of Santa Cruz" },
    { value: "university-of-tarija", label: "University of Tarija" },
    { value: "university-of-potisi", label: "University of Potosí" },
  ],
  "Bosnia and Herzegovina": [
    { value: "university-of-sarajevo", label: "University of Sarajevo" },
    { value: "university-of-banja-luka", label: "University of Banja Luka" },
    { value: "university-of-mostar", label: "University of Mostar" },
    { value: "university-of-tuzla", label: "University of Tuzla" },
  ],
  Botswana: [
    { value: "university-of-botswana", label: "University of Botswana" },
    {
      value: "botswana-international-university-of-science-and-technology",
      label: "Botswana International University of Science and Technology",
    },
  ],
  Brazil: [
    { value: "university-of-sao-paulo", label: "University of São Paulo" },
    {
      value: "university-of-rio-de-janeiro",
      label: "University of Rio de Janeiro",
    },
    {
      value: "federal-university-of-minas-gerais",
      label: "Federal University of Minas Gerais",
    },
    {
      value: "federal-university-of-rio-grande-do-sul",
      label: "Federal University of Rio Grande do Sul",
    },
    {
      value: "federal-university-of-bahia",
      label: "Federal University of Bahia",
    },
    { value: "university-of-brasilia", label: "University of Brasília" },
    { value: "university-of-parana", label: "University of Paraná" },
    {
      value: "federal-university-of-pernambuco",
      label: "Federal University of Pernambuco",
    },
    {
      value: "federal-university-of-rio-de-janeiro",
      label: "Federal University of Rio de Janeiro",
    },
  ],
  Brunei: [
    {
      value: "university-of-brunei-darussalam",
      label: "University of Brunei Darussalam",
    },
    { value: "brunei-muara-college", label: "Brunei Muara College" },
  ],
  Bulgaria: [
    { value: "sofian-university", label: "Sofia University" },
    {
      value: "technical-university-of-sofia",
      label: "Technical University of Sofia",
    },
    { value: "plovdiv-university", label: "Plovdiv University" },
    { value: "university-of-varna", label: "University of Varna" },
    {
      value: "medical-university-of-sofia",
      label: "Medical University of Sofia",
    },
  ],
  "Burkina Faso": [
    { value: "university-of-ouagadougou", label: "University of Ouagadougou" },
    {
      value: "university-of-burkina-faso",
      label: "University of Burkina Faso",
    },
    { value: "university-of-koudougou", label: "University of Koudougou" },
  ],
  "Cabo Verde": [
    { value: "university-of-cabo-verde", label: "University of Cabo Verde" },
    { value: "institute-of-cabo-verde", label: "Institute of Cabo Verde" },
  ],
  Cambodia: [
    {
      value: "royal-university-of-phnom-penh",
      label: "Royal University of Phnom Penh",
    },
    {
      value: "royal-university-of-agriculture",
      label: "Royal University of Agriculture",
    },
    {
      value: "royal-university-of-law-and-economics",
      label: "Royal University of Law and Economics",
    },
    {
      value: "national-university-of-management",
      label: "National University of Management",
    },
  ],
  Cameroon: [
    { value: "university-of-yaounde-i", label: "University of Yaoundé I" },
    { value: "university-of-douala", label: "University of Douala" },
    { value: "university-of-buea", label: "University of Buea" },
    { value: "university-of-nsukka", label: "University of Nsukka" },
    {
      value: "higher-institute-of-business-administration",
      label: "Higher Institute of Business Administration",
    },
  ],
  "Central African Republic": [
    { value: "university-of-bangui", label: "University of Bangui" },
    {
      value: "central-african-university",
      label: "Central African University",
    },
  ],
  Chad: [
    { value: "university-of-n-djamena", label: "University of N’Djamena" },
    {
      value: "higher-institute-of-management",
      label: "Higher Institute of Management",
    },
  ],
  Chile: [
    { value: "university-of-chile", label: "University of Chile" },
    {
      value: "pontifical-catholic-university-of-chile",
      label: "Pontifical Catholic University of Chile",
    },
    { value: "university-of-concepcion", label: "University of Concepción" },
    { value: "university-of-santiago", label: "University of Santiago" },
  ],

  Colombia: [
    {
      value: "national-university-of-colombia",
      label: "National University of Colombia",
    },
    { value: "los-andes-university", label: "Los Andes University" },
    { value: "university-of-the-norte", label: "University of the Norte" },
    { value: "university-of-cauca", label: "University of Cauca" },
    {
      value: "pontifical-javieriana-university",
      label: "Pontifical Javieriana University",
    },
  ],
  Comoros: [{ value: "comoros-university", label: "Comoros University" }],
  "Congo, Democratic Republic of the": [
    { value: "university-of-kinshasa", label: "University of Kinshasa" },
    { value: "university-of-lubumbashi", label: "University of Lubumbashi" },
    { value: "university-of-mbuji-mayi", label: "University of Mbuji Mayi" },
  ],
  "Congo, Republic of the": [
    { value: "university-of-brazzaville", label: "University of Brazzaville" },
  ],
  "Costa Rica": [
    {
      value: "national-university-of-costa-rica",
      label: "National University of Costa Rica",
    },
    { value: "university-of-costa-rica", label: "University of Costa Rica" },
    {
      value: "institute-of-technology-of-costa-rica",
      label: "Institute of Technology of Costa Rica",
    },
  ],
  "Côte d’Ivoire": [
    { value: "university-of-abidjan", label: "University of Abidjan" },
    { value: "university-of-pele-foro", label: "University of Pele Forou" },
    { value: "university-of-ibadan", label: "University of Ibadan" },
  ],
  Croatia: [
    { value: "university-of-zagreb", label: "University of Zagreb" },
    { value: "university-of-split", label: "University of Split" },
    { value: "university-of-rijeka", label: "University of Rijeka" },
    { value: "university-of-osijek", label: "University of Osijek" },
  ],
  Cuba: [
    { value: "university-of-havana", label: "University of Havana" },
    {
      value: "central-university-of-venezuela",
      label: "Central University of Venezuela",
    },
    { value: "university-of-cienfuegos", label: "University of Cienfuegos" },
  ],
  Cyprus: [
    { value: "university-of-cyprus", label: "University of Cyprus" },
    {
      value: "cyprus-university-of-technology",
      label: "Cyprus University of Technology",
    },
    {
      value: "american-university-of-cyprus",
      label: "American University of Cyprus",
    },
  ],
  "Czech Republic": [
    { value: "charles-university", label: "Charles University" },
    {
      value: "czech-technical-university",
      label: "Czech Technical University",
    },
    { value: "masaryk-university", label: "Masaryk University" },
    {
      value: "university-of-economics-prague",
      label: "University of Economics, Prague",
    },
  ],
  Denmark: [
    { value: "university-of-copenhagen", label: "University of Copenhagen" },
    { value: "aarhus-university", label: "Aarhus University" },
    {
      value: "technical-university-of-denmark",
      label: "Technical University of Denmark",
    },
    { value: "university-of-roskilde", label: "University of Roskilde" },
  ],
  Djibouti: [
    { value: "university-of-djibouti", label: "University of Djibouti" },
  ],
  Dominica: [
    {
      value: "university-of-the-west-indies-dominica",
      label: "University of the West Indies, Dominica",
    },
    { value: "dominica-state-college", label: "Dominica State College" },
  ],
  "Dominican Republic": [
    {
      value: "universidad-autonoma-de-santo-domingo",
      label: "Universidad Autónoma de Santo Domingo",
    },
    {
      value: "pontifical-catholic-university-of-madre-y-maestra",
      label: "Pontifical Catholic University of Madre y Maestra",
    },
    {
      value: "universidad-central-del-este",
      label: "Universidad Central del Este",
    },
  ],
  "East Timor (Timor-Leste)": [
    {
      value: "national-university-of-east-timor",
      label: "National University of East Timor",
    },
  ],
  Ecuador: [
    {
      value: "central-university-of-ecuador",
      label: "Central University of Ecuador",
    },
    {
      value: "universidad-espol",
      label: "Escuela Superior Politécnica del Litoral",
    },
    {
      value: "university-of-the-hemisphere",
      label: "University of the Hemisphere",
    },
    {
      value: "san-francisco-university-of-quito",
      label: "San Francisco University of Quito",
    },
  ],
  Egypt: [
    { value: "cairo-university", label: "Cairo University" },
    { value: "alexandria-university", label: "Alexandria University" },
    { value: "ain-shams-university", label: "Ain Shams University" },
    {
      value: "american-university-in-cairo",
      label: "American University in Cairo",
    },
  ],
  "El Salvador": [
    { value: "university-of-el-salvador", label: "University of El Salvador" },
    {
      value: "centro-american-university",
      label: "Centro American University",
    },
  ],
  "Equatorial Guinea": [
    {
      value: "university-of-equatorial-guinea",
      label: "University of Equatorial Guinea",
    },
  ],
  Eritrea: [{ value: "asmera-university", label: "Asmara University" }],
  Estonia: [
    { value: "university-of-tartu", label: "University of Tartu" },
    {
      value: "tallinn-university-of-technology",
      label: "Tallinn University of Technology",
    },
    { value: "tallinn-university", label: "Tallinn University" },
  ],
  Eswatini: [
    { value: "university-of-eswatini", label: "University of Eswatini" },
  ],
  Ethiopia: [
    { value: "addis-ababa-university", label: "Addis Ababa University" },
    { value: "jimma-university", label: "Jimma University" },
    { value: "mekelle-university", label: "Mekelle University" },
  ],
  Fiji: [
    { value: "the-university-of-fiji", label: "The University of Fiji" },
    { value: "fiji-national-university", label: "Fiji National University" },
  ],
  Finland: [
    { value: "university-of-helsinki", label: "University of Helsinki" },
    {
      value: "helsinki-metropolitan-university",
      label: "Helsinki Metropolitan University",
    },
    { value: "tampere-university", label: "Tampere University" },
    { value: "university-of-turku", label: "University of Turku" },
  ],

  Gabon: [{ value: "university-of-owendo", label: "University of Owendo" }],
  "The Gambia": [
    { value: "university-of-the-gambia", label: "University of The Gambia" },
  ],
  Georgia: [
    { value: "tbilisi-state-university", label: "Tbilisi State University" },
    {
      value: "georgian-technical-university",
      label: "Georgian Technical University",
    },
    { value: "ilias-university", label: "Ilias University" },
  ],

  Ghana: [
    { value: "university-of-ghana", label: "University of Ghana" },
    {
      value: "kwame-nkrumah-university-of-science-and-technology",
      label: "Kwame Nkrumah University of Science and Technology",
    },
    {
      value: "university-of-development-studies",
      label: "University of Development Studies",
    },
  ],
  Greece: [
    { value: "university-of-athens", label: "University of Athens" },
    {
      value: "national-technical-university-of-athens",
      label: "National Technical University of Athens",
    },
    {
      value: "university-of-thessaloniki",
      label: "University of Thessaloniki",
    },
  ],
  Grenada: [
    { value: "st-george-s-university", label: "St. George’s University" },
  ],
  Guatemala: [
    { value: "san-carlos-university", label: "San Carlos University" },
    { value: "mariana-university", label: "Mariana University" },
  ],
  Guinea: [{ value: "university-of-conakry", label: "University of Conakry" }],
  "Guinea-Bissau": [
    { value: "university-of-bissau", label: "University of Bissau" },
  ],
  Guyana: [{ value: "university-of-guyana", label: "University of Guyana" }],
  Haiti: [{ value: "university-of-berne", label: "University of Bern" }],
  Honduras: [
    {
      value: "national-university-of-honduras",
      label: "National University of Honduras",
    },
  ],
  Hungary: [
    { value: "university-of-budapest", label: "University of Budapest" },
    { value: "semmelweis-university", label: "Semmelweis University" },
    { value: "debrecen-university", label: "Debrecen University" },
  ],
  Iceland: [{ value: "university-of-iceland", label: "University of Iceland" }],

  Indonesia: [
    { value: "indonesia-university", label: "University of Indonesia" },
    { value: "gadjah-mada-university", label: "Gadjah Mada University" },
    {
      value: "bandung-institute-of-technology",
      label: "Bandung Institute of Technology",
    },
  ],
  Iran: [
    { value: "tehran-university", label: "University of Tehran" },
    { value: "shiraz-university", label: "Shiraz University" },
    {
      value: "isfahan-university-of-technology",
      label: "Isfahan University of Technology",
    },
  ],
  Iraq: [
    { value: "university-of-baghdad", label: "University of Baghdad" },
    { value: "basra-university", label: "University of Basra" },
  ],
  Ireland: [
    { value: "university-college-dublin", label: "University College Dublin" },
    { value: "trinity-college-dublin", label: "Trinity College Dublin" },
    { value: "university-of-galway", label: "University of Galway" },
  ],
  Israel: [
    {
      value: "hebrew-university-of-jerusalem",
      label: "Hebrew University of Jerusalem",
    },
    {
      value: "technion-israel-institute-of-technology",
      label: "Technion - Israel Institute of Technology",
    },
    { value: "tel-aviv-university", label: "Tel Aviv University" },
  ],

  Jamaica: [
    {
      value: "university-of-the-west-indies-mona",
      label: "University of the West Indies, Mona",
    },
  ],
  Japan: [
    { value: "university-of-tokyo", label: "University of Tokyo" },
    { value: "kyoto-university", label: "Kyoto University" },
    { value: "osaka-university", label: "Osaka University" },
    {
      value: "tokyo-institute-of-technology",
      label: "Tokyo Institute of Technology",
    },
  ],
  Jordan: [
    { value: "university-of-jordan", label: "University of Jordan" },
    {
      value: "jordan-university-of-science-and-technology",
      label: "Jordan University of Science and Technology",
    },
  ],
  Kazakhstan: [
    {
      value: "al-farabi-kazakh-national-university",
      label: "Al-Farabi Kazakh National University",
    },
    { value: "nazarbayev-university", label: "Nazarbayev University" },
    {
      value: "kazakh-british-technical-university",
      label: "Kazakh-British Technical University",
    },
  ],
  Kenya: [
    { value: "university-of-nairobi", label: "University of Nairobi" },
    {
      value: "jomo-kenyatta-university-of-agriculture-and-technology",
      label: "Jomo Kenyatta University of Agriculture and Technology",
    },
    { value: "maseno-university", label: "Maseno University" },
  ],
  Kiribati: [
    { value: "university-of-kiribati", label: "University of Kiribati" },
  ],
  "Korea, North": [
    { value: "kim-il-sung-university", label: "Kim Il-sung University" },
  ],
  "Korea, South": [
    { value: "seoul-national-university", label: "Seoul National University" },
    { value: "korea-university", label: "Korea University" },
    { value: "yonsei-university", label: "Yonsei University" },
    { value: "postech", label: "POSTECH" },
  ],
  Kosovo: [
    { value: "university-of-pristina", label: "University of Pristina" },
  ],
  Kuwait: [
    { value: "kuwait-university", label: "Kuwait University" },
    {
      value: "american-university-of-kuwait",
      label: "American University of Kuwait",
    },
  ],
  Kyrgyzstan: [
    { value: "bishkek-state-university", label: "Bishkek State University" },
    {
      value: "kyrgyz-national-university",
      label: "Kyrgyz National University",
    },
  ],
  Laos: [
    {
      value: "national-university-of-laos",
      label: "National University of Laos",
    },
  ],
  Latvia: [
    { value: "university-of-latvia", label: "University of Latvia" },
    { value: "riga-technical-university", label: "Riga Technical University" },
    { value: "riga-stradins-university", label: "Riga Stradins University" },
  ],
  Lebanon: [
    {
      value: "american-university-of-beirut",
      label: "American University of Beirut",
    },
    {
      value: "lebanese-american-university",
      label: "Lebanese American University",
    },
    { value: "university-of-beirut", label: "University of Beirut" },
  ],
  Lesotho: [
    {
      value: "national-university-of-lesotho",
      label: "National University of Lesotho",
    },
  ],
  Liberia: [{ value: "university-of-liberia", label: "University of Liberia" }],
  Libya: [
    { value: "university-of-tripoli", label: "University of Tripoli" },
    { value: "al-fateh-university", label: "Al-Fateh University" },
  ],
  Liechtenstein: [
    {
      value: "university-of-liechtenstein",
      label: "University of Liechtenstein",
    },
  ],
  Lithuania: [
    { value: "vilnius-university", label: "Vilnius University" },
    {
      value: "kaunas-university-of-technology",
      label: "Kaunas University of Technology",
    },
    {
      value: "vytuatas-magnus-university",
      label: "Vytautas Magnus University",
    },
  ],
  Luxembourg: [
    { value: "university-of-luxembourg", label: "University of Luxembourg" },
  ],
  Madagascar: [
    {
      value: "university-of-antananarivo",
      label: "University of Antananarivo",
    },
    { value: "university-of-toliara", label: "University of Toliara" },
  ],
  Malawi: [
    { value: "university-of-malawi", label: "University of Malawi" },
    {
      value: "blantyre-international-university",
      label: "Blantyre International University",
    },
  ],
  Malaysia: [
    { value: "university-of-malaya", label: "University of Malaya" },
    {
      value: "university-of-sains-malaysia",
      label: "University of Sains Malaysia",
    },
    {
      value: "university-of-technology-malaysia",
      label: "University of Technology Malaysia",
    },
    {
      value: "monash-university-malaysia",
      label: "Monash University Malaysia",
    },
  ],
  Maldives: [
    {
      value: "maldives-national-university",
      label: "Maldives National University",
    },
    { value: "addu-university", label: "Addu University" },
  ],
  Mali: [
    { value: "university-of-bamako", label: "University of Bamako" },
    { value: "university-of-kangaba", label: "University of Kangaba" },
  ],
  Malta: [{ value: "university-of-malta", label: "University of Malta" }],
  "Marshall Islands": [
    {
      value: "college-of-the-marshall-islands",
      label: "College of the Marshall Islands",
    },
  ],
  Mauritania: [
    { value: "university-of-nouakchott", label: "University of Nouakchott" },
  ],
  Mauritius: [
    { value: "university-of-mauritius", label: "University of Mauritius" },
    {
      value: "mauritius-university-of-science-and-technology",
      label: "Mauritius University of Science and Technology",
    },
  ],
  Mexico: [
    {
      value: "national-autonomous-university-of-mexico",
      label: "National Autonomous University of Mexico",
    },
    {
      value: "instituto-politecnico-nacional",
      label: "Instituto Politécnico Nacional",
    },
    {
      value: "universidad-de-las-americas-puebla",
      label: "Universidad de las Américas Puebla",
    },
    { value: "university-of-guadalajara", label: "University of Guadalajara" },
  ],
  "Micronesia, Federated States of": [
    { value: "college-of-micronesia-fsm", label: "College of Micronesia FSM" },
  ],
  Moldova: [
    { value: "moldova-state-university", label: "Moldova State University" },
    {
      value: "technical-university-of-moldova",
      label: "Technical University of Moldova",
    },
  ],
  Monaco: [{ value: "universite-de-monaco", label: "Université de Monaco" }],
  Mongolia: [
    {
      value: "national-university-of-mongolia",
      label: "National University of Mongolia",
    },
    {
      value: "mongolian-university-of-science-and-technology",
      label: "Mongolian University of Science and Technology",
    },
  ],
  Montenegro: [
    { value: "university-of-montenegro", label: "University of Montenegro" },
  ],
  Morocco: [
    { value: "mohammed-v-university", label: "Mohammed V University" },
    { value: "hassan-ii-university", label: "Hassan II University" },
  ],
  Mozambique: [
    {
      value: "eduardo-mondlane-university",
      label: "Eduardo Mondlane University",
    },
    { value: "university-of-maputo", label: "University of Maputo" },
  ],
  "Myanmar (Burma)": [
    { value: "yangon-university", label: "Yangon University" },
    { value: "mandalay-university", label: "Mandalay University" },
  ],
  Namibia: [
    { value: "university-of-namibia", label: "University of Namibia" },
    {
      value: "namibian-university-of-science-and-technology",
      label: "Namibian University of Science and Technology",
    },
  ],
  Nauru: [
    { value: "nauru-national-university", label: "Nauru National University" },
  ],
  Nepal: [
    { value: "tribhuvan-university", label: "Tribhuvan University" },
    { value: "kathmandu-university", label: "Kathmandu University" },
    { value: "pokhara-university", label: "Pokhara University" },
  ],
  Netherlands: [
    { value: "university-of-amsterdam", label: "University of Amsterdam" },
    {
      value: "delft-university-of-technology",
      label: "Delft University of Technology",
    },
    {
      value: "erasmus-university-rotterdam",
      label: "Erasmus University Rotterdam",
    },
    { value: "utrecht-university", label: "Utrecht University" },
  ],
  "New Zealand": [
    { value: "university-of-auckland", label: "University of Auckland" },
    { value: "university-of-canterbury", label: "University of Canterbury" },
    {
      value: "university-of-wellington",
      label: "Victoria University of Wellington",
    },
    {
      value: "auckland-university-of-technology",
      label: "Auckland University of Technology",
    },
  ],
  Nicaragua: [
    {
      value: "national-autonomous-university-of-nicaragua",
      label: "National Autonomous University of Nicaragua",
    },
    { value: "university-of-nicaragua", label: "University of Nicaragua" },
  ],
  Niger: [{ value: "university-of-niamey", label: "University of Niamey" }],
  "North Macedonia": [
    {
      value: "ss-cyril-and-methodius-university",
      label: "Ss. Cyril and Methodius University",
    },
  ],
  Norway: [
    { value: "university-of-oslo", label: "University of Oslo" },
    {
      value: "norwegian-university-of-science-and-technology",
      label: "Norwegian University of Science and Technology",
    },
    { value: "university-of-bergen", label: "University of Bergen" },
  ],
  Oman: [
    { value: "sultan-qaboos-university", label: "Sultan Qaboos University" },
  ],
  Pakistan: [
    { value: "quaid-e-azam-university", label: "Quaid-e-Azam University" },
    {
      value: "lahore-university-of-management-sciences",
      label: "Lahore University of Management Sciences",
    },
    { value: "university-of-karachi", label: "University of Karachi" },
  ],
  Palau: [
    { value: "palau-community-college", label: "Palau Community College" },
  ],
  Panama: [
    { value: "university-of-panama", label: "University of Panama" },
    {
      value: "technological-university-of-panama",
      label: "Technological University of Panama",
    },
  ],
  "Papua New Guinea": [
    {
      value: "university-of-papua-new-guinea",
      label: "University of Papua New Guinea",
    },
  ],
  Paraguay: [
    {
      value: "national-university-of-asuncion",
      label: "National University of Asuncion",
    },
  ],
  Peru: [
    {
      value: "pontifical-catholic-university-of-peru",
      label: "Pontifical Catholic University of Peru",
    },
    {
      value: "national-university-of-san-marcos",
      label: "National University of San Marcos",
    },
  ],
  Philippines: [
    {
      value: "university-of-the-philippines",
      label: "University of the Philippines",
    },
    {
      value: "ateneo-de-manila-university",
      label: "Ateneo de Manila University",
    },
    { value: "de-la-salle-university", label: "De La Salle University" },
  ],
  Poland: [
    { value: "university-of-warsaw", label: "University of Warsaw" },
    { value: "jagiellonian-university", label: "Jagiellonian University" },
  ],
  Portugal: [
    { value: "university-of-lisbon", label: "University of Lisbon" },
    { value: "university-of-porto", label: "University of Porto" },
  ],
  Qatar: [{ value: "qatar-university", label: "Qatar University" }],
  Romania: [
    { value: "university-of-bucharest", label: "University of Bucharest" },
    { value: "babes-bolyai-university", label: "Babes-Bolyai University" },
  ],
  Russia: [
    {
      value: "lomonosov-moscow-state-university",
      label: "Lomonosov Moscow State University",
    },
    {
      value: "saint-petersburg-state-university",
      label: "Saint Petersburg State University",
    },
  ],
  Rwanda: [{ value: "university-of-rwanda", label: "University of Rwanda" }],
  "Saint Kitts and Nevis": [
    {
      value: "windsor-university-school-of-medicine",
      label: "Windsor University School of Medicine",
    },
  ],
  "Saint Lucia": [
    {
      value: "spartan-health-sciences-university",
      label: "Spartan Health Sciences University",
    },
  ],
  "Saint Vincent and the Grenadines": [
    {
      value: "saint-james-school-of-medicine",
      label: "Saint James School of Medicine",
    },
  ],
  Samoa: [
    {
      value: "national-university-of-samoa",
      label: "National University of Samoa",
    },
  ],
  "San Marino": [
    {
      value: "university-of-the-republic-of-san-marino",
      label: "University of the Republic of San Marino",
    },
  ],
  "Sao Tome and Principe": [
    {
      value: "university-of-sao-tome-and-principe",
      label: "University of Sao Tome and Principe",
    },
  ],
  "Saudi Arabia": [
    { value: "king-saud-university", label: "King Saud University" },
    { value: "king-abdulaziz-university", label: "King Abdulaziz University" },
  ],
  Senegal: [
    {
      value: "cheikh-anta-diop-university",
      label: "Cheikh Anta Diop University",
    },
  ],
  Serbia: [
    { value: "university-of-belgrade", label: "University of Belgrade" },
  ],
  Seychelles: [
    { value: "university-of-seychelles", label: "University of Seychelles" },
  ],
  "Sierra Leone": [
    {
      value: "university-of-sierra-leone",
      label: "University of Sierra Leone",
    },
  ],
  Singapore: [
    {
      value: "national-university-of-singapore",
      label: "National University of Singapore",
    },
    {
      value: "nanyang-technological-university",
      label: "Nanyang Technological University",
    },
  ],
  Slovakia: [{ value: "comenius-university", label: "Comenius University" }],
  Slovenia: [
    { value: "university-of-ljubljana", label: "University of Ljubljana" },
  ],
  "Solomon Islands": [
    {
      value: "solomon-islands-national-university",
      label: "Solomon Islands National University",
    },
  ],
  Somalia: [
    {
      value: "somali-national-university",
      label: "Somali National University",
    },
  ],
  "South Africa": [
    { value: "university-of-cape-town", label: "University of Cape Town" },
    {
      value: "university-of-the-witwatersrand",
      label: "University of the Witwatersrand",
    },
  ],
  Spain: [
    { value: "university-of-barcelona", label: "University of Barcelona" },
    {
      value: "autonomous-university-of-madrid",
      label: "Autonomous University of Madrid",
    },
  ],
  "Sri Lanka": [
    { value: "university-of-colombo", label: "University of Colombo" },
  ],
  Sudan: [{ value: "university-of-khartoum", label: "University of Khartoum" }],
  "Sudan, South": [
    { value: "university-of-juba", label: "University of Juba" },
  ],
  Suriname: [
    {
      value: "anton-de-kom-university-of-suriname",
      label: "Anton de Kom University of Suriname",
    },
  ],
  Sweden: [
    { value: "lund-university", label: "Lund University" },
    { value: "uppsala-university", label: "Uppsala University" },
  ],
  Switzerland: [
    { value: "eth-zurich", label: "ETH Zurich" },
    { value: "university-of-zurich", label: "University of Zurich" },
  ],
  Syria: [{ value: "university-of-damascus", label: "University of Damascus" }],
  Taiwan: [
    {
      value: "national-taiwan-university",
      label: "National Taiwan University",
    },
  ],
  Tajikistan: [
    { value: "tajik-national-university", label: "Tajik National University" },
  ],
  Tanzania: [
    {
      value: "university-of-dar-es-salaam",
      label: "University of Dar es Salaam",
    },
  ],
  Thailand: [
    { value: "chulalongkorn-university", label: "Chulalongkorn University" },
  ],
  Togo: [{ value: "university-of-lome", label: "University of Lome" }],
  Tonga: [
    {
      value: "tonga-institute-of-higher-education",
      label: "Tonga Institute of Higher Education",
    },
  ],
  "Trinidad and Tobago": [
    {
      value: "university-of-the-west-indies",
      label: "University of the West Indies",
    },
  ],
  Tunisia: [{ value: "university-of-tunis", label: "University of Tunis" }],
  Turkey: [
    { value: "bogazici-university", label: "Bogazici University" },
    {
      value: "middle-east-technical-university",
      label: "Middle East Technical University",
    },
  ],
  Turkmenistan: [
    { value: "turkmen-state-university", label: "Turkmen State University" },
  ],
  Tuvalu: [
    {
      value: "university-of-the-south-pacific",
      label: "University of the South Pacific",
    },
  ],
  Uganda: [{ value: "makerere-university", label: "Makerere University" }],
  Ukraine: [
    {
      value: "taras-shevchenko-national-university",
      label: "Taras Shevchenko National University",
    },
  ],
  "United Arab Emirates": [
    {
      value: "united-arab-emirates-university",
      label: "United Arab Emirates University",
    },
    { value: "khalifa-university", label: "Khalifa University" },
  ],
  Uruguay: [
    {
      value: "university-of-the-republic",
      label: "University of the Republic",
    },
  ],
  Uzbekistan: [
    {
      value: "national-university-of-uzbekistan",
      label: "National University of Uzbekistan",
    },
  ],
  Vanuatu: [
    {
      value: "university-of-the-south-pacific",
      label: "University of the South Pacific",
    },
  ],
  "Vatican City": [
    { value: "pontifical-university", label: "Pontifical University" },
  ],
  Venezuela: [
    {
      value: "central-university-of-venezuela",
      label: "Central University of Venezuela",
    },
  ],
  Vietnam: [
    {
      value: "vietnam-national-university",
      label: "Vietnam National University",
    },
  ],
  Yemen: [{ value: "university-of-sanaa", label: "University of Sanaa" }],
  Zambia: [{ value: "university-of-zambia", label: "University of Zambia" }],
  Zimbabwe: [
    { value: "university-of-zimbabwe", label: "University of Zimbabwe" },
  ],
};

module.exports = {
  name,
  company,
  logo,
  websiteURL,
  serverURL,
  paystackEnabled,
  flutterwaveEnabled,
  partnerServer,
  FreeType,
  FreeCost,
  FreeTime,
  MonthType,
  MonthCost,
  MonthTime,
  YearType,
  YearCost,
  YearTime,
  review,
  from,
  profession,
  photoURL,
  paystackPlanIdOne,
  paystackPlanIdTwo,
  amountInZarOne,
  amountInZarTwo,
  flutterwavePlanIdMonthly,
  flutterwavePlanIdYearly,
  amountInGBPMonthly,
  amountInGBPYearly,
  languages,
  level,
  DiscountMonth,
  Discount,
  countries,
  universities,
  curriculums,
  quizURL,
  country,

};
