import React, { useEffect, useState } from "react";
import { Card, Button, Badge, Avatar, Tooltip } from 'flowbite-react';
import Header from "../components/header";
import Footers from "../components/footers";
import UserCourses from "../components/usercourses";
import { serverURL } from "../constants";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { StreakPopup } from "src/components/StreakPopup";
import { redirect, Link, useNavigate } from "react-router-dom";
import { HiAcademicCap, HiFire, HiLightningBolt, HiClock } from 'react-icons/hi';
import { BarChartStreak } from "src/components/bar-graph";
import EmailVerificationBar from "src/components/EmailVerificationBar";

const Home = () => {
  const [userPlan, setUserPlan] = useState("Free");
  const [streakDays, setStreakDays] = useState(0);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [upcomingDeadlines, setUpcomingDeadlines] = useState([]);
  const [isVerified, setIsVerified] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const courseTopics = [
      'Machine Learning Basics', 'Advanced React Patterns', 'Data Structures in Python',
      'Introduction to AI', 'Deep Learning with TensorFlow', 'Natural Language Processing',
      'Computer Vision', 'Data Analysis with Pandas', 'Web Development with Django',
      'Mobile App Development with Flutter', 'Cloud Computing with AWS', 'Cybersecurity Fundamentals',
      'Blockchain Technology', 'Quantum Computing', 'Big Data Analytics', 'Internet of Things (IoT)',
      'Augmented Reality', 'Virtual Reality', 'Game Development with Unity', 'DevOps with Kubernetes'
    ];

    const quizDeadlines = [
      'Quiz: Machine Learning Basics', 'Quiz: Advanced React Patterns', 'Quiz: Data Structures in Python',
      'Quiz: Introduction to AI', 'Quiz: Deep Learning with TensorFlow', 'Quiz: Natural Language Processing',
      'Quiz: Computer Vision', 'Quiz: Data Analysis with Pandas', 'Quiz: Web Development with Django',
      'Quiz: Mobile App Development with Flutter', 'Quiz: Cloud Computing with AWS', 'Quiz: Cybersecurity Fundamentals',
      'Quiz: Blockchain Technology', 'Quiz: Quantum Computing', 'Quiz: Big Data Analytics', 'Quiz: Internet of Things (IoT)',
      'Quiz: Augmented Reality', 'Quiz: Virtual Reality', 'Quiz: Game Development with Unity', 'Quiz: DevOps with Kubernetes'
    ];

    const type = sessionStorage.getItem("type");
    const email = sessionStorage.getItem("email");
    const userId = sessionStorage.getItem("userId");

    const TestPayment = async (email) => {
      try {
        const dataToSend = { email };
        const postURL = `${serverURL}/api/expiredPaymentValidation`;
        const res = await axios.post(postURL, dataToSend);

        if (res.data.expired === true) {
          setUserPlan("Free");
          showToast("You are in The Free Plan");
        } else {
          sessionStorage.setItem("expiredDate", res.data.expiredDate);
          setUserPlan(sessionStorage.getItem("type"));
          showToast(`You are in ${sessionStorage.getItem("type")}`);
        }
      } catch (error) {
        showToast("An error occurred during payment validation");
      }
    };

    const fetchStreakData = async () => {
      try {
        const { data } = await axios.post(
          `${serverURL}/api/NotificationStreakCheck`,
          { email, type: "check" }
        );

        if (data) {
          setStreakDays(data.teacherStreakCount || 0);
        }
      } catch (error) {
        console.error("Error fetching streak data:", error);
      }
    };

    const fetchVerificationStatus = async () => {
      try {
        const { data } = await axios.get(`${serverURL}/api/checkVerificationStatus`, {
          params: { email }
        });
        setIsVerified(data.isVerified);
      } catch (error) {
        console.error("Error fetching verification status:", error);
      }
    };

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    const selectUniqueCourses = () => {
      const shuffledCourses = shuffleArray([...courseTopics]);
      return shuffledCourses.slice(0, 3); // Select 3 unique courses
    };

    const selectUniqueDeadlines = () => {
      const shuffledDeadlines = shuffleArray([...quizDeadlines]);
      return shuffledDeadlines.slice(0, 3); // Select 3 unique deadlines
    };

    if (type !== "free") {
      TestPayment(email);
    }

    if (!userId) {
      redirect("/");
    }

    if (email) {
      fetchStreakData();
      fetchVerificationStatus();
    }

    setRecommendedCourses(selectUniqueCourses());
    setUpcomingDeadlines(selectUniqueDeadlines());
  }, []);

  const showToast = (msg) => {
    toast(msg, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleStartLearningClick = () => {
    const type = sessionStorage.getItem("type");
    if (!isVerified) {
      showToast("You need to verify your email in order to create a course");
    } else if (type === "free") {
      navigate("/trialpayments");
    } else {
      navigate("/create");
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-r from-blue-500 to-indigo-600 text-white">
      <Header isHome={true} className="sticky top-0 z-50" />
      {!isVerified && <EmailVerificationBar />} {/* Conditionally render EmailVerificationBar */}
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <Card className="col-span-2 bg-white text-blue-900">
            <h5 className="text-2xl font-bold tracking-tight">
              Welcome back, {sessionStorage.getItem("mName")}!
            </h5>
            <p className="font-normal">
              Learn A Course everyday to get points and maintain your streaks score to be top of the leaderboard
            </p>
            <div className="flex items-center space-x-4 mt-4">
              <Badge color="info" size="xl" icon={HiAcademicCap}>
                {userPlan} 
              </Badge>
              <Tooltip content="Your current streak">
                <Badge color="warning" size="xl" icon={HiFire}>
                  {streakDays} Day Streak
                </Badge>
              </Tooltip>
            </div>
            <Button className="mt-4 bg-white text-blue-600 hover:bg-blue-50" onClick={handleStartLearningClick}>
              <HiLightningBolt className="mr-2 h-5 w-5" />
              Start Learning
            </Button>
          </Card>
          <Card className="bg-white text-blue-900">
            <BarChartStreak />
          </Card>
        </div>
        
        <Card className="mb-8 bg-white text-blue-900">
          <div className="flex justify-between items-center mb-4">
            <h5 className="text-xl font-bold tracking-tight">
              Your Courses
            </h5>
            <Link to="/courses">
              <Button outline className="text-blue-600 border-blue-600 hover:bg-blue-50">
                View All
              </Button>
            </Link>
          </div>
          <UserCourses userId={sessionStorage.getItem("uid")} />
        </Card>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Card className="bg-white text-blue-900">
            <h5 className="text-xl font-bold tracking-tight mb-2">
              Recommended for You
            </h5>
            <ul className="divide-y divide-gray-200">
              {recommendedCourses.map((course, index) => (
                <li key={index} className="py-3 sm:py-4">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <Avatar rounded size="md" />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {course}
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        Intermediate • 4 hours
                      </p>
                    </div>
                    <Link to="/create">
                      <Button size="xs" outline className="text-pink-600 border-pink-600 hover:bg-pink-50">
                        Enroll
                      </Button>
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          </Card>
          <Card className="bg-white text-blue-900">
            <h5 className="text-xl font-bold tracking-tight mb-2">
              Upcoming Deadlines
            </h5>
            <ul className="divide-y divide-gray-200">
              {upcomingDeadlines.map((task, index) => (
                <li key={index} className="py-3 sm:py-4">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <HiClock className="h-6 w-6 text-gray-400" />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {task}
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        Due in 2 days
                      </p>
                    </div>
                    <Link to="https://quiz.learnrithm.com">
                      <Badge color="failure" className="cursor-pointer">Urgent</Badge>
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          </Card>
        </div>
      </main>
      <Footers className="mt-auto bg-gradient-to-r from-blue-500 to-indigo-600 text-white" />
      <ToastContainer />
      <StreakPopup
        title="Streak Achievement!"
        score="Day sign in Streak"
        message="You're on a roll! Keep signing in daily for more rewards."
        position="sign-in-teacher"
      />
    </div>
  );
};

export default Home;