import { Navbar, Dropdown } from 'flowbite-react';
import React, { useEffect, useState, useCallback } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import AdminSidebar from './components/adminsidebar';
import AdminHead from './components/adminhead';
import AdminSidebarMobile from './components/adminsidebarmobile';
import { serverURL } from '../constants';
import axios from 'axios';
import CourseTable from './components/coursetable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Courses = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState('all');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    sessionStorage.setItem('darkMode', false);
    async function dashboardData() {
      const postURL = serverURL + `/api/getcourses`;
      const response = await axios.get(postURL);
      setData(response.data);
      setFilteredData(response.data); // Initialize filteredData with fetched data
    }
    dashboardData();
  }, []);

  const sortData = useCallback((criteria) => {
    let sorted = [...data];
    const now = new Date();
    if (criteria === 'date') {
      sorted = sorted.filter(course => new Date(course.date).toDateString() === now.toDateString());
    } else if (criteria === 'week') {
      const weekNumber = getWeekNumber(now);
      sorted = sorted.filter(course => getWeekNumber(new Date(course.date)) === weekNumber);
    } else if (criteria === 'month') {
      const month = now.getMonth();
      sorted = sorted.filter(course => new Date(course.date).getMonth() === month);
    } else if (criteria === 'range') {
      sorted = sorted.filter(course => new Date(course.date) >= startDate && new Date(course.date) <= endDate);
    }
    setFilteredData(sorted);
  }, [data, startDate, endDate]);

  useEffect(() => {
    sortData(filterCriteria);
  }, [data, filterCriteria, sortData]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleFilterChange = (criteria) => {
    setFilterCriteria(criteria);
  };

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  return (
    <>
      <div>
        <div className="flex bg-white dark:bg-black md:hidden pb-10 overflow-y-auto">
          <div className={`fixed inset-0 bg-black opacity-50 z-50 ${isSidebarOpen ? 'block' : 'hidden'}`} onClick={toggleSidebar}></div>
          <div className="flex-1 flex flex-col overflow-hidden">
            <div>
              <Navbar fluid className='py-3 dark:bg-black bg-white border-black dark:text-white dark:border-white md:border-b'>
                <Navbar.Brand className='ml-1'>
                  <p className='font-black text-xl'>Admin Panel</p>
                </Navbar.Brand>
                <div className='flex md:hidden justify-center items-center'>
                  {isSidebarOpen ? (
                    <FiX
                      onClick={toggleSidebar}
                      className='mx-2'
                      size={20}
                      color={sessionStorage.getItem('darkMode') === 'true' ? 'white' : 'black'}
                    />
                  ) : (
                    <FiMenu
                      onClick={toggleSidebar}
                      className='mx-2'
                      size={20}
                      color={sessionStorage.getItem('darkMode') === 'true' ? 'white' : 'black'}
                    />
                  )}
                </div>
              </Navbar>
              <div className="flex justify-end p-4">
                <Dropdown label="Filter By">
                  <Dropdown.Item onClick={() => handleFilterChange('all')}>All</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleFilterChange('date')}>Date</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleFilterChange('week')}>Week</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleFilterChange('month')}>Month</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleFilterChange('range')}>Date Range</Dropdown.Item>
                </Dropdown>
              </div>
              {filterCriteria === 'range' && (
                <div className="flex justify-end p-4">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="End Date"
                  />
                </div>
              )}
              <CourseTable datas={filteredData} />
            </div>
            <AdminSidebarMobile isSidebarOpen={isSidebarOpen} />
          </div>
        </div>
        <div className='flex flex-row overflow-y-auto h-screen max-md:hidden no-scrollbar'>
          <AdminSidebar />
          <div className='overflow-y-auto flex-grow flex-col dark:bg-black'>
            <AdminHead />
            <div className="flex justify-end p-4">
              <Dropdown label="Filter By">
                <Dropdown.Item onClick={() => handleFilterChange('all')}>All</Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilterChange('date')}>Date</Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilterChange('week')}>Week</Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilterChange('month')}>Month</Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilterChange('range')}>Date Range</Dropdown.Item>
              </Dropdown>
            </div>
            {filterCriteria === 'range' && (
              <div className="flex justify-end p-4">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="End Date"
                />
              </div>
            )}
            <CourseTable datas={filteredData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;