import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import slide from '../../res/img/slideOne.png';
import { logo, name, websiteURL } from 'src/constants';

const SlideOne = () => {
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-500 to-indigo-600">
      <Helmet>
        <title>{name}</title>
        <meta
          name="description"
          content={`${name} elevates your learning journey with AI-powered personalized education tailored to your needs.`}
        />
        <meta
          name="keywords"
          content={`${name}, AI Teacher, Personalized Learning, Modern Education, AI Education Platform`}
        />
        <meta property="og:title" content={`${name}`} />
        <meta
          property="og:description"
          content={`${name} elevates your learning journey with AI-powered personalized education tailored to your needs.`}
        />
        <meta property="og:url" content={websiteURL} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${name}`} />
        <meta
          name="twitter:description"
          content={`${name} elevates your learning journey with AI-powered personalized education tailored to your needs.`}
        />
        <meta name="twitter:image" content={logo} />
      </Helmet>

      {/* Announcement Banner */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex justify-center w-full py-4"
      >
        <motion.div className="inline-flex items-center gap-2 px-4 py-2 bg-white/95 rounded-full text-sm text-gray-900 max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
          <span>🎉 The Android App is Coming Soon To Google Play Store</span>
          <motion.span
            animate={{ x: [0, 3, 0] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
          >
            →
          </motion.span>
        </motion.div>
      </motion.div>

      {/* Main Content */}
      <motion.div
        className="container mx-auto px-4 pt-20 pb-32"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="max-w-4xl mx-auto text-center" variants={itemVariants}>
          <h1 className="text-7xl font-bold tracking-tight max-md:text-4xl">
            <span className="text-gray-200">The </span>
            <span className="text-white">AI Teacher</span>
            <span className="text-gray-200"> for</span>
            <br />
            <span className="text-gray-200">modern learning</span>
          </h1>

          <motion.p
            className="mt-8 text-xl text-gray-200 max-w-2xl mx-auto leading-relaxed max-md:text-lg"
            variants={itemVariants}
          >
            {name} takes your learning journey to the next level with AI-powered personalized education that adapts to your needs.
          </motion.p>

          <motion.div
            className="flex items-center justify-center gap-4 mt-12"
            variants={itemVariants}
          >
            <button
              onClick={() => navigate('/signin')}
              className="px-6 py-3 text-sm font-medium text-white border border-white/20 rounded-full hover:bg-white/10 transition-colors duration-300"
            >
              Sign In
            </button>
            <button
              onClick={() => navigate('/signup')}
              className="px-6 py-3 text-sm font-medium text-indigo-600 bg-white rounded-full hover:bg-gray-100 transition-colors duration-300"
            >
              Get Started
            </button>
          </motion.div>
        </motion.div>

        <motion.div
          className="mt-20 max-w-5xl mx-auto"
          variants={itemVariants}
          whileHover={{ scale: 1.02 }}
          transition={{ type: 'spring', stiffness: 300, damping: 20 }}
        >
          <div className="relative rounded-2xl overflow-hidden shadow-2xl">
            <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent pointer-events-none" />
            <img
              src={slide}
              alt={`${name} AI Teacher Platform`}
              className="w-full h-auto"
            />
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SlideOne;