import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Spinner } from 'flowbite-react';
import { HiOutlineAcademicCap, HiOutlineArrowLeft } from 'react-icons/hi';
import { serverURL, level, curriculums, countries, universities } from '../constants';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Topics = () => {
    const { state } = useLocation();
    const [processing, setProcessing] = useState(false);
    const { jsonData, mainTopic, type, language } = state || {};

    const navigate = useNavigate();

    useEffect(() => {
        if (!jsonData) {
            navigate("/create");
        }
    }, [jsonData, navigate]);

    function redirectCreate() {
        navigate("/create");
    }

    const showToast = async (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    function redirectCourse() {
        const mainTopicData = jsonData[mainTopic][0];
        const firstSubtopic = mainTopicData.subtopics[0];

        if (type === 'video & text course') {
            const query = `${firstSubtopic.title} ${mainTopic} in english`;
            sendVideo(query, firstSubtopic.title);
            setProcessing(true);
        } else {
            const prompt = `Teach me extensively about the subtopic "${firstSubtopic.title}" within the main topic "${mainTopic}", as if you were teaching a ${level} student, considering the ${curriculums} used in ${countries}. Provide detailed explanations with examples, and include citations from relevant ${universities} websites or educational resources that align with the standards of ${curriculums} in ${countries}. Use ${language} to ensure better understanding. Please avoid providing additional resources or images.`;

            const promptImage = `Provide an example of ${firstSubtopic.title} within the context of ${mainTopic}, considering the ${curriculums} used in ${countries}. Ensure the image clearly illustrates this concept in a way that aligns with the educational standards of ${universities} in ${countries} and is appropriate for a ${level} student.`;

            setProcessing(true);
            sendPrompt(prompt, promptImage);
        }
    }

    async function sendPrompt(prompt, promptImage) {
        const dataToSend = {
            prompt: prompt,
        };
        try {
            const postURL = serverURL + '/api/generate';
            const res = await axios.post(postURL, dataToSend);
            const generatedText = res.data.text;
            const htmlContent = generatedText;

            try {
                const parsedJson = htmlContent;
                sendImage(parsedJson, promptImage);
            } catch (error) {
                sendPrompt(prompt, promptImage)
            }
        } catch (error) {
            sendPrompt(prompt, promptImage)
        }
    }

    async function sendImage(parsedJson, promptImage) {
        const dataToSend = {
            prompt: promptImage,
        };
        try {
            const postURL = serverURL + '/api/image';
            const res = await axios.post(postURL, dataToSend);
            try {
                const generatedText = res.data.url;
                sendData(generatedText, parsedJson);
                setProcessing(false);
            } catch (error) {
                sendImage(parsedJson, promptImage)
            }
        } catch (error) {
            sendImage(parsedJson, promptImage)
        }
    }

    async function sendData(image, theory) {
        jsonData[mainTopic][0].subtopics[0].theory = theory;
        jsonData[mainTopic][0].subtopics[0].image = image;

        const user = sessionStorage.getItem('uid');
        const email = sessionStorage.getItem('email');
        const content = JSON.stringify(jsonData);
        const postURL = serverURL + '/api/course';
        const response = await axios.post(postURL, { user, content, type, mainTopic, language, email});

        if (response.data.success) {
            showToast(response.data.message);
           
            sessionStorage.setItem('courseId', response.data.courseId);
            sessionStorage.setItem('first', response.data.completed);
            sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
            navigate('/course', { state: { jsonData: jsonData, mainTopic: mainTopic.toUpperCase(), type: type.toLowerCase(), courseId: response.data.courseId, end: '' } });
        } else {
            sendData(image, theory)
        }
    }

    async function sendDataVideo(image, theory) {
        jsonData[mainTopic][0].subtopics[0].theory = theory;
        jsonData[mainTopic][0].subtopics[0].youtube = image;

        const user = sessionStorage.getItem('uid');
        const content = JSON.stringify(jsonData);
        const postURL = serverURL + '/api/course';
        const response = await axios.post(postURL, { user, content, type, mainTopic });

        if (response.data.success) {
            showToast(response.data.message);
       
            sessionStorage.setItem('courseId', response.data.courseId);
            sessionStorage.setItem('first', response.data.completed);
            sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
            navigate('/course', { state: { jsonData: jsonData, mainTopic: mainTopic.toUpperCase(), type: type.toLowerCase(), courseId: response.data.courseId, end: '' } });
        } else {
            sendDataVideo(image, theory)
        }
    }

    async function sendVideo(query, subtopic) {
        const dataToSend = {
            prompt: query,
        };
        try {
            const postURL = serverURL + '/api/yt';
            const res = await axios.post(postURL, dataToSend);

            try {
                const generatedText = res.data.url;
                sendTranscript(generatedText, subtopic);
            } catch (error) {
                sendVideo(query, subtopic)
            }
        } catch (error) {
            sendVideo(query, subtopic)
        }
    }

    async function sendTranscript(url, subtopic) {
        const dataToSend = {
            prompt: url,
        };
        try {
            const postURL = serverURL + '/api/transcript';
            const res = await axios.post(postURL, dataToSend);

            try {
                const generatedText = res.data.url;
                const allText = generatedText.map(item => item.text);
                const concatenatedText = allText.join(' ');
                const prompt = `Teach me this theory as if you were explaining it to a ${level} student, considering the ${curriculums} used in ${countries}. Explain "${concatenatedText}" using language and examples appropriate for this educational level and aligned with the standards of ${universities} in ${countries}. Please provide the explanation in ${language} to ensure better comprehension all in ${concatenatedText}.`;

                sendSummery(prompt, url);
            } catch (error) {
                const prompt = `Teach me about the subtopic "${subtopic}" within the main topic "${mainTopic}" as if you were a professional teacher explaining it to a ${level} student, considering the ${curriculums} used in ${countries}. Provide clear examples and ensure the content is validated by ${universities} from ${countries} and other educational websites. Please avoid providing additional resources or images, and use ${language} to enhance understanding.`;

                sendSummery(prompt, url);
            }
        } catch (error) {
            const prompt = `Teach me about the subtopic "${subtopic}" within the main topic "${mainTopic}" in a way suitable for a ${level} student, considering the ${curriculums} used in ${countries}. Provide clear examples validated by ${universities} from ${countries} to illustrate the concept. Please do not include additional resources or images.`;

            sendSummery(prompt, url);
        }
    }

    async function sendSummery(prompt, url) {
        const dataToSend = {
            prompt: prompt,
        };
        try {
            const postURL = serverURL + '/api/generate';
            const res = await axios.post(postURL, dataToSend);
            const generatedText = res.data.text;
            const htmlContent = generatedText;

            try {
                const parsedJson = htmlContent;
                setProcessing(false);
                sendDataVideo(url, parsedJson);
            } catch (error) {
                sendSummery(prompt, url)
            }
        } catch (error) {
            sendSummery(prompt, url)
        }
    }

    const renderTopicsAndSubtopics = (topics) => {
        try {
            return (
                <div className="space-y-6">
                    {topics.map((topic) => (
                        <Card key={topic.title} className="overflow-hidden">
                            <h3 className="text-xl font-bold text-gray-800 mb-4 bg-blue-100 -mx-4 -mt-4 p-4">{topic.title}</h3>
                            <div className="space-y-2">
                                {topic.subtopics.map((subtopic) => (
                                    <p key={subtopic.title} className="text-gray-700 bg-gray-50 p-3 rounded-lg">{subtopic.title}</p>
                                ))}
                            </div>
                        </Card>
                    ))}
                </div>
            );
        } catch (error) {
            return (
                <div className="space-y-6">
                    {topics.map((topic) => (
                        <Card key={topic.title} className="overflow-hidden">
                            <h3 className="text-xl font-bold text-gray-800 mb-4 bg-blue-100 -mx-4 -mt-4 p-4">{topic.title}</h3>
                            <div className="space-y-2">
                                {topic.subtopics.map((subtopic) => (
                                    <p key={subtopic.title} className="text-gray-700 bg-gray-50 p-3 rounded-lg">{subtopic.title}</p>
                                ))}
                            </div>
                        </Card>
                    ))}
                </div>
            );
        }
    };

    return (
        <div className="min-h-screen flex flex-col bg-gradient-to-br from-blue-50 to-indigo-100">
            <Header isHome={true} className="sticky top-0 z-50" />
            <div className="flex-1 container mx-auto px-4 py-8">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-4xl md:text-5xl font-extrabold text-center text-blue-800 mb-6">{mainTopic.toUpperCase()}</h1>
                    <p className="text-center text-xl text-gray-600 mb-8">List of topics and subtopics your course will cover</p>
                    {jsonData && renderTopicsAndSubtopics(jsonData[mainTopic])}
                    <div className="mt-10 space-y-4">
                        <Button
                            onClick={redirectCourse}
                            disabled={processing}
                            className="w-full"
                            gradientDuoTone="cyanToBlue"
                            size="xl"
                        >
                            {processing ? (
                                <>
                                    <Spinner size="sm" light={true} />
                                    <span className="ml-2">Processing...</span>
                                </>
                            ) : (
                                <>
                                    <HiOutlineAcademicCap className="mr-2 h-5 w-5" />
                                    Teach Me
                                </>
                            )}
                        </Button>
                        <Button
                            onClick={redirectCreate}
                            outline={true}
                            gradientDuoTone="cyanToBlue"
                            size="xl"
                            className="w-full"
                        >
                            <HiOutlineArrowLeft className="mr-2 h-5 w-5" />
                            Go Back
                        </Button>
                    </div>
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
            <ToastContainer />
        </div>
    );
};

export default Topics;