import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footers from "../components/footers";
import successG from "../res/img/success.gif";
import axios from "axios";
import { quizURL, serverURL, websiteURL } from "src/constants";
import { redirect } from "react-router-dom";

const VerifyEmail = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function verifyEmail() {
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get("token");
      const app = searchParams.get("app");
      const postURL = serverURL + "/api/verify-email";
      const res = await axios.post(postURL, { token });

      if (res.data.status === 200) {
        setError('');
        setSuccess(true);
        setTimeout(() => {
          if (app === "AIQuiz") {
            redirect(quizURL);
          } else if (app === "AITeacher") {
            redirect(websiteURL);
          }
        }, 10000);
      } else {
        setError('Issue verifying the email');
      }
    }

    verifyEmail();
  }, []);

  async function resendVerifyingEmail() {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get("email");
    const postURL = serverURL + "/api/resendVerificationEmail";
    
    try {
      const res = await axios.post(postURL, { email });
      if (res.data.success) {
        setError("Verification email resent.");
      } else {
        setError("Error resending email");
      }
    } catch (err) {
      setError("Failed to resend the email.");
    }
  }

  return (
    <div className="h-screen flex flex-col">
      <Header isHome={false} className="sticky top-0 z-50" />
      <div className="dark:bg-black flex-1">
        {error ? (
          <div className="flex flex-col items-center justify-center h-full gap-3">
            <p className="text-white text-4xl font-bold">{error}</p>
            <button onClick={resendVerifyingEmail} disabled={error === "Verification email resent."}>
              Resend Email
            </button>
          </div>
        ) : success ? (
          <div className="flex flex-col items-center justify-center h-full gap-3">
            <p className="text-white text-4xl font-bold">Email Verified successfully</p>
            <img src={successG} alt="Success" width={100} height={100} />
            <p className="text-white text-lg">Redirecting in 10 seconds...</p>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full gap-3">
            <p className="text-white text-4xl font-bold">Loading ...</p>
          </div>
        )}
      </div>
      <Footers className="sticky bottom-0 z-50" />
    </div>
  );
};

export default VerifyEmail;
